import Vue from 'vue'
import Vuex from 'vuex'
import { collection, addDoc, doc, setDoc, getDocs, getDoc, updateDoc } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { db, auth } from '../../firebase'
import router from '@/router';

Vue.use(Vuex)

export const authentication = {
  namespaced: true,
  state: {
    user: null,
    loading: {
      isLoading: false,
      errorMessage: ''
    }
  },
  getters: {
  },
  mutations: {
    SET_USER: (state, payload) => state.user = payload,
    SET_LOADING: (state, payload) => {
      state.loading.isLoading = payload.isLoading
      state.loading.errorMessage = payload.errorMessage
    }
  },
  actions: {
    signInUser({ commit }, data) {
      commit('SET_LOADING', {isLoading: true, errorMessage: ''})
      signInWithEmailAndPassword(auth, data.email, data.password)
        .then(async (userCredential) => {
          // Signed in 
          const user = userCredential.user
          console.log(userCredential)

          const docRef = doc(db, "accounts", data.email);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            localStorage.setItem('user', JSON.stringify({
              reference: docSnap.data().reference.toString(),
              username: docSnap.data().username,
              role: docSnap.data().role,
              permissions: docSnap.data().permissions,
              token: user.accessToken
            }))

            commit('SET_USER', docSnap.data())

            if (docSnap.data().role === 'student') {
              router.push({ name: 'Profile' })
            } else {
              router.push({ name: 'Dashboard' })
            }

            console.log("Document data:", docSnap.data())
          } else {
            console.log("No such document!");
          }

          commit('SET_LOADING', {isLoading: false, errorMessage: ''})

        })
        .catch((error) => {
          commit('SET_LOADING', {isLoading: false, errorMessage: "Incorrect email or password."})
        });
    },

    signOutUser({ commit }, data) {
      commit('SET_USER', null)
      localStorage.removeItem('user')

      if (!localStorage.getItem('user')) {
        router.push({ name: 'Login' })
      }
    },

    registerUser({ commit }, data) {
      createUserWithEmailAndPassword(auth, data.email, data.password)
        .then((userCredential) => {
          const user = userCredential.user;
          console.log(user)
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorMessage)
          // ..
        });
    }
  },
  modules: {
  }
}
import Vue from 'vue'
import Vuex from 'vuex'
import { collection, addDoc, doc, setDoc, getDocs, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { db, auth } from '../../firebase'
import router from '@/router';

Vue.use(Vuex)

export const students = {
    namespaced: true,
    state: {
        snackbars: {
            add: {
                isSnackbar: false,
                isSuccess: false,
                message: null,
                timeout: 3000
            },
            delete: {
                isSnackbar: false,
                isSuccess: false,
                message: null,
                timeout: 3000
            },
            save: {
                isSnackbar: false,
                isSuccess: false,
                message: null,
                timeout: 3000
            }
        },

        allStudents: [],
        singleStudent: null,
    },
    getters: {
        getStatusMessage: state => state.statusMessage,
        getStudents: state => state.allStudents,
        getStudent: state => state.singleStudent,
    },
    mutations: {
        SET_STATUS_MESSAGE: (state, payload) => {
            if (payload.snackbar === 'add') {
                state.snackbars.add.isSnackbar = payload.isSnackbar
                state.snackbars.add.message = payload.message
                state.snackbars.add.isSuccess = payload.isSuccess
            } else if (payload.snackbar === 'delete') {
                state.snackbars.delete.isSnackbar = payload.isSnackbar
                state.snackbars.delete.message = payload.message
                state.snackbars.delete.isSuccess = payload.isSuccess
            }
        },

        SET_ALL_STUDENTS: (state, payload) => {
            state.allStudents = payload
        },

        SET_SINGLE_STUDENT: (state, payload) => state.singleStudent = payload,

    },
    actions: {

        async addNewStudent({ commit }, data) {
            console.log('addNewStudent');
            commit('SET_STATUS_MESSAGE', { message: null, isSnackbar: false, isSuccess: false, snackbar: 'add' })
            const email = data.reference.toString() + '@gmail.com'
            const password = 'chrono' + data.reference.toString()
            // Add a new document in collection "students"
            try {
                await setDoc(doc(db, "students", data.reference.toString()), data);

                //Registers student with email and password
                createUserWithEmailAndPassword(auth, email, password)
                    .then((userCredential) => {
                        const user = userCredential.user;
                        console.log(user)
                    })
                    .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        console.log(errorMessage)
                        // ..
                    });

                //Saves account information in firebase accounts collection
                await setDoc(doc(db, "accounts", email), {
                    email: email,
                    reference: Number(data.reference),
                    role: 'student',
                    username: data.firstName + data.lastName,
                    permissions: ['profile', 'gamify']
                });

                //Adds new student to the list of students qualified for monthly best in attendance award
                const awardRef = doc(db, "achievements", "BAA");
                const awardDocSnap = await getDoc(awardRef);

                //BAA for Best in Attendance Award
                if (awardDocSnap.exists()) {
                    console.log("Document data:", awardDocSnap.data())
                    let awardees = awardDocSnap.data().awardees
                    console.log(awardees);
                    awardees.push({
                        reference: data.reference,
                        status: 'not awarded',
                        streak: 0
                    })

                    await updateDoc(doc(db, "achievements", 'BAA'), { awardees: awardees });
                    console.log(awardees);

                } else {
                    // awardDocSnap.data() will be undefined in this case
                    console.log("No such document!");
                }

                commit('SET_STATUS_MESSAGE', { message: 'A new student has been added successfully.', isSnackbar: true, isSuccess: true, snackbar: 'add' })
            } catch (error) {
                console.error("Error adding document: ", error);
                commit('SET_STATUS_MESSAGE', { message: 'An error occured. Failed to add new student.', isSnackbar: true, isSuccess: false, snackbar: 'add' })
            }
        },

        async getAllStudents({ commit }) {
            let students = []

            // Get all students/documents in collection "students"
            // const querySnapshot = await getDocs(collection(db, "students"));
            await getDocs(collection(db, "students"))
                .then(querySnapshot => {
                    querySnapshot.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        students.push(doc.data())
                        console.log(doc.id, " => ", doc.data());
                    })
                })
                .catch(error => {
                    console.log(error);
                })
            // querySnapshot.forEach((doc) => {
            //     // doc.data() is never undefined for query doc snapshots
            //     students.push(doc.data())
            //     console.log(doc.id, " => ", doc.data());
            // })

            commit('SET_ALL_STUDENTS', students)
        },

        async getSingleStudent({ commit }, data) {
            console.log(data);
            const docRef = doc(db, "students", data.toString());
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                commit('SET_SINGLE_STUDENT', docSnap.data())
                console.log("Document data:", docSnap.data())
            } else {
                // docSnap.data() will be undefined in this case
                console.log("No such document!");
            }
        },

        async updateStudentDetails({ commit }, data) {
            // Add a new document in collection "students"
            console.log('updateStudentDetails');
            try {
                await setDoc(doc(db, "students", data.reference.toString()), data);
            } catch (error) {
                console.error("Error adding document: ", error);
            }
        },

        async deleteStudent({ commit }, data) {
            console.log(data);
            try {
                await deleteDoc(doc(db, "students", data.reference.toString()));
            } catch (error) {
                console.log(error)
            }

            router.push({ name: 'Students' })
        }
    },
    modules: {
    }
}
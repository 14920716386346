<template>
  <div flat>
    <v-row dense>
      <!-- <v-col :cols="resizeCols.searchBar.cols" :sm="resizeCols.searchBar.sm" :md="resizeCols.searchBar.md"
        :lg="resizeCols.searchBar.lg" class="px-0 order-last order-sm-first">
        <v-text-field v-model="search" single-line outlined dense prepend-inner-icon="mdi-magnify"
          label="Search" class="mx-1"></v-text-field>
      </v-col> -->

      <v-col cols="12" class="px-0">
        <v-text-field v-model="search" single-line outlined dense prepend-inner-icon="mdi-magnify" label="Search"
          class="mx-1"></v-text-field>
      </v-col>

      <!-- Creates an optional primary button -->
      <!-- <v-col v-if="isPrimary" :cols="resizeCols.button.cols" :sm="resizeCols.button.sm" :md="resizeCols.button.md"
        :lg="resizeCols.button.lg" class="order-first order-sm-2">
        <v-btn block color="#0D47A1" class="white--text" @click="useCustomBtn(buttonsDetails.primary.name)">
          <v-icon class="mr-2">{{ buttonsDetails.primary.icon }}</v-icon>
          {{ buttonsDetails.primary.name }}
        </v-btn>
      </v-col> -->

      <!-- Creates an optional secondary button -->
      <!-- <v-col v-if="isSecondary" :cols="resizeCols.button.cols" :sm="resizeCols.button.sm" :md="resizeCols.button.md"
        :lg="resizeCols.button.lg" class="order-first order-sm-last">
        <v-btn block outlined color="red" @click="useCustomBtn(buttonsDetails.secondary.name)">
          <v-icon class="mr-2">{{ buttonsDetails.secondary.icon }}</v-icon>
          {{ buttonsDetails.secondary.name }}
        </v-btn>
      </v-col> -->
    </v-row>

    <!-- The actual table -->
    <v-data-table :headers="headers" :items="items" :search="search">
      <template v-slot:item.actions="{ item }">
        <td>
          <v-icon color="#0D47A1" @click="enableEdit(item)">mdi-pencil</v-icon>
          <!-- <v-icon color="red" @click="showDelete(item)">mdi-delete</v-icon> -->
        </td>
      </template>
    </v-data-table>

  </div>
</template>

<script>
export default {
  name: 'Table',

  props: [
    'headers',
    'items',
    // 'isPrimary',
    // 'isSecondary',
    // 'buttonsDetails'
  ],

  data: () => ({
    search: null,
  }),

  methods: {
    // useCustomBtn: function (buttonName) {
    //   // Enables the functionality of primary and secondary button by emitting an event in the parent component
    //   switch (buttonName) {
    //     case 'Save':
    //       this.$emit('primaryBtnFunction')
    //       break;

    //     case 'Discard':
    //       this.$emit('secondaryBtnFunction')
    //       break;

    //     default:
    //       break;
    //   }
    // }
    enableEdit: function (item) {
      this.$emit('edit', item)
      console.log(item)
    },

    showDelete: function (item) {
      this.$emit('delete', item)
      console.log(item)
    },
  },

  computed: {
    // resizeCols() {
    //   // Sets the size of the buttons and search bar within the table when there is/there's no custom buttons
    //   if (this.isPrimary && this.isSecondary) {
    //     return {
    //       searchBar: {
    //         cols: "12",
    //         sm: "6",
    //         md: "6",
    //         lg: "8"
    //       },

    //       button: {
    //         cols: "12",
    //         sm: "3",
    //         md: "3",
    //         lg: "2"
    //       }
    //     }
    //   } else if (this.isPrimary || this.isSecondary) {
    //     return {
    //       searchBar: {
    //         cols: "12",
    //         sm: "9",
    //         md: "9",
    //         lg: "10"
    //       },

    //       button: {
    //         cols: "12",
    //         sm: "3",
    //         md: "3",
    //         lg: "2"
    //       }
    //     }
    //   } else if (!this.isPrimary && !this.isSecondary) {
    //     return {
    //       searchBar: {
    //         cols: "12",
    //         sm: "12",
    //         md: "12",
    //         lg: "12"
    //       }
    //     }
    //   }
    // },
  }
};

</script>

<style scoped>
/* *{
  border: 1px solid red;
} */
</style>
import Attendance from '../../views/Attendance/Attendance'

export default [
    {
        path: '/attendance',
        name: 'Attendance',
        component: Attendance,
        meta: {
            isAuthenticated: true,
            permissions: ['DEVELOPER', 'INSTRUCTOR']
        }
    },
]
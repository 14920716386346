<template>
    <v-container>
        <!-- Developers profile -->
        <v-card flat max-width="500" class="mx-auto mt-10 d-flex flex-column align-center">
            <v-card-title><span style="color: #0D47A1;" class="font-weight-bold text-h5 mb-4">DEVELOPERS</span></v-card-title>

            <v-card class="avatars">
                <v-img max-width="150" class="mx-auto image" contain src="../../assets/avatars/cath.png" alt="cathlyn"></v-img>
            </v-card >
            <v-card-title><span style="color: #0D47A1;" class="font-weight-bold">LAPID</span></v-card-title>
            <v-card-subtitle class="pb-0">Cathlyn Mae D.</v-card-subtitle>
            <p class="text-overline my-0 pt-0">Backend Developer</p>
            <div class="d-flex justify-space-around" style="width: 75px;">
                <a href="https://www.facebook.com/cathlapid">
                    <img src="../../assets/facebook.png" alt="fb_logo" style="width: 1.5rem;">
                </a>
                <a href="mailto:pr.cmlapid@gmail.com">
                    <img src="../../assets/mail.png" alt="mail_logo" style="width: 1.5rem;">
                </a>
            </div>

            <v-card class="avatars mt-10">
                <v-img max-width="150" class="mx-auto image" contain src="../../assets/avatars/charles.png" alt="charles"></v-img>
            </v-card >
            <v-card-title><span style="color: #0D47A1;" class="font-weight-bold">CLEMENTE</span></v-card-title>
            <v-card-subtitle class="pb-0">Prince Charles M.</v-card-subtitle>
            <p class="text-overline my-0 pt-0">Frontend Developer</p>
            <div class="d-flex justify-space-around" style="width: 75px;">
                <a href="https://www.facebook.com/princecharles.clemente/">
                    <img src="../../assets/facebook.png" alt="fb_logo" style="width: 1.5rem;">
                </a>
                <a href="mailto:clemente.pcharles@gmail.com">
                    <img src="../../assets/mail.png" alt="mail_logo" style="width: 1.5rem;">
                </a>
            </div>

            <v-card class="avatars mt-10">
                <v-img max-width="150" class="mx-auto image" contain src="../../assets/avatars/gab.png" alt="gab"></v-img>
            </v-card >
            <v-card-title><span style="color: #0D47A1;" class="font-weight-bold">OCAMPO</span></v-card-title>
            <v-card-subtitle class="pb-0">Gabriel Benne E.</v-card-subtitle>
            <p class="text-overline my-0 pt-0">Quality Assurance Tester</p>
            <div class="d-flex justify-space-around" style="width: 75px;">
                <a href="https://web.facebook.com/Terrabyteee">
                    <img src="../../assets/facebook.png" alt="fb_logo" style="width: 1.5rem;">
                </a>
                <a href="mailto:akatsukion2002@gmail.com">
                    <img src="../../assets/mail.png" alt="mail_logo" style="width: 1.5rem;">
                </a>
            </div>

            <!-- <v-card-title><span style="color: #0D47A1;" class="font-weight-bold text-h5 mb-4 mt-10">COORDINATOR</span></v-card-title> -->

            <v-card class="avatars mt-10">
                <v-img max-width="150" class="mx-auto image" contain src="../../assets/avatars/carlo.png" alt="carlo"></v-img>
            </v-card >
            <v-card-title><span style="color: #0D47A1;" class="font-weight-bold">GARCIA</span></v-card-title>
            <v-card-subtitle class="pb-0">John Carlo P.</v-card-subtitle>
            <p class="text-overline my-0 pt-0">Project Coordinator</p>
            <div class="d-flex justify-space-around mb-10" style="width: 75px;">
                <a href="https://www.facebook.com/carlo.p.garcia.1">
                    <img src="../../assets/facebook.png" alt="fb_logo" style="width: 1.5rem;">
                </a>
                <a href="mailto:gjc032192@gmail.com">
                    <img src="../../assets/mail.png" alt="mail_logo" style="width: 1.5rem;">
                </a>
            </div>
        </v-card>
    </v-container>
</template>
  
<script>
export default {
    name: 'Developers',

    data: () => ({

    })
};

</script>
  
<style scoped>
.avatars{
    border: 1px solid #0D47A1;
    border-radius: 100%;
}

.image{
    border-radius: 100%;
}
</style>
<template>
  <v-container>
    <Loading v-if="!isPageLoaded" />

    <div v-if="isPageLoaded">
      <!-- Form for creating a new attendance or updating/using existing attendance -->
      <v-dialog max-width="500" max-height="500" persistent v-model="dialogs.scanSuccess">
        <v-card class="pa-4">
          <v-card-title><span class="mx-auto" style="color: #0D47A1;">NOTIFY GUARDIAN OF</span></v-card-title>

          <v-card-text class="mt-4 text-center">
            <v-card-subtitle class="pb-0">{{ scannedStudent.name }}</v-card-subtitle>
            <v-card-subtitle class="pt-0">TIME: {{ scannedStudent.arrivedTime }}</v-card-subtitle>
          </v-card-text>

          <v-btn block class="mt-2 white--text" color="#0D47A1" @click="notifyGuardian()">
            <v-icon class="mr-2">mdi-send</v-icon>
            NOTIFY
          </v-btn>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogs.attendance" max-width="500" max-height="500" persistent>
        <v-card class="pa-4">
          <v-card-title><span class="mx-auto" style="color: #0D47A1;">ATTENDANCE</span></v-card-title>

          <v-dialog width="290px" v-model="dialogs.datePicker">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field outlined label="DATE" v-model="newAttendanceForm.date" v-on="on" v-bind="attrs"
                readonly></v-text-field>
            </template>
            <v-date-picker v-model="newAttendanceForm.date" scrollable></v-date-picker>
          </v-dialog>

          <v-select outlined label="TIME" v-model="newAttendanceForm.time" :items="timeOptions"></v-select>

          <v-btn block class="mt-2 white--text" color="#0D47A1" @click="createAttendance()">
            <v-icon class="mr-2">mdi-plus-box</v-icon>
            CREATE ATTENDANCE
          </v-btn>
          <v-btn block outlined class="mt-2" color="red" @click="dialogs.attendance = false">
            <v-icon class="mr-2">mdi-close</v-icon>
            CLOSE
          </v-btn>
        </v-card>
      </v-dialog>

      <!-- Table to display attendance informations with custom buttons -->
      <p class="mt-4 text-h6 font-weight-bold" id="check-attendance-title">Check Attendance</p>
      <v-row>
        <v-col cols="12" md="9" class="order-last order-md-first">
          <v-card flat>
            <!-- <Table :headers="tableHeaders" :items="tableItems" :isPrimary="true" :buttonsDetails="tableButtons"
            :isSecondary="true" @primaryBtnFunction="saveAttendance()" @secondaryBtnFunction="discardAttendance()" /> -->
            <Table :headers="tableHeaders" :items="tableItems" @edit="editRow($event)" />
          </v-card>
        </v-col>

        <!-- QR code scanner -->
        <v-col cols="12" md="3" class="order-first order-md-last">
          <Scanner @addAttendance="checkAbsents() ? dialogs.absents = true : saveAttendace()" :buttons="scannerButtons" />
        </v-col>
      </v-row>

      <!-- Absent students dialog -->
      <v-dialog v-model="dialogs.absents" max-width="500" max-height="500" persistent>
        <v-card class="pa-4">
          <v-card-title><span class="mx-auto" style="color: #0D47A1;">ABSENT STUDENTS</span></v-card-title>
          <v-card-text class="text-center">
            The following students will be marked as absents:
          </v-card-text>

          <v-card-text class="text-center">
            <v-card-subtitle v-for="(student, index) in absentStudents" :key="index" class="pb-0">{{ student.lastName }},
              {{
                student.firstName }} {{ student.middleName }}</v-card-subtitle>
          </v-card-text>

          <v-btn block class="mt-2 white--text" color="#0D47A1" @click="saveAttendace()">
            <v-icon class="mr-2">mdi-content-save</v-icon>
            SAVE ANYWAY
          </v-btn>
          <v-btn block outlined class="mt-2" color="red" @click="dialogs.absents = false">
            <v-icon class="mr-2">mdi-close</v-icon>
            CLOSE
          </v-btn>
        </v-card>
      </v-dialog>

      <v-snackbar class="text-center" v-model="snackbars.save.isSnackbar" :timeout="snackbars.save.timeout" top
        :color="snackbars.save.isSuccess ? 'success' : 'red accent-2'">
        {{ snackbars.save.message }}
      </v-snackbar>

      <!-- Dialog for editing time in attendance -->
      <v-dialog v-model="dialogs.edit" max-width="500" max-height="500" persistent>
        <v-card class="pa-4">
          <v-card-title><span class="mx-auto" style="color: #0D47A1;">CHANGE TIME</span></v-card-title>

          <v-dialog width="290px" v-model="dialogs.timePicker">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field outlined label="TIME" v-model="edittedTime" v-on="on" v-bind="attrs" readonly></v-text-field>
            </template>
            <v-time-picker format="ampm" v-model="edittedTime"></v-time-picker>
          </v-dialog>

          <v-btn block class="mt-2 white--text" color="#0D47A1" @click="saveNewTime()">
            <v-icon class="mr-2">mdi-content-save</v-icon>
            SAVE NEW TIME
          </v-btn>

          <v-btn block outlined class="mt-2" color="#1976D2" @click="clearTime()">
            <v-icon class="mr-2">mdi-pause-box</v-icon>
            CLEAR SCANNED TIME
          </v-btn>

          <v-btn block text class="mt-2" color="red" @click="dialogs.edit = false">
            <v-icon class="mr-2">mdi-close</v-icon>
            CLOSE
          </v-btn>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import Scanner from '../ScanCode/Scanner.vue'
import Table from '../../components/Table.vue'
import Loading from '../../components/Loading.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ScanCode',

  components: {
    Scanner,
    Table,
    Loading
  },

  data: () => ({
    isPageLoaded: false,
    badges: [
      { month: 'january', name: 'aquarius' },
      { month: 'february', name: 'pisces' },
      { month: 'march', name: 'aries' },
      { month: 'april', name: 'taurus' },
      { month: 'may', name: 'gemini' },
      { month: 'june', name: 'scorpio' },
      { month: 'july', name: 'leo' },
      { month: 'august', name: 'virgo' },
      { month: 'september', name: 'libra' },
      { month: 'october', name: 'scorpio' },
      { month: 'november', name: 'sagittarius' },
      { month: 'december', name: 'capricorn' },
    ],

    scannerButtons: {
      save: true,
      discard: true,
      reward: false
    },
    dialogs: {
      attendance: true,
      datePicker: false,
      scanSuccess: false,
      absents: false,
      edit: false,
      delete: false,
      timePicker: false,
    },

    timeOptions: ['Morning', 'Afternoon'],

    scannedStudent: {
      name: '',
      arrivedTime: '--',
    },

    newAttendanceForm: {
      // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date: null,
      time: null,
    },

    attendanceDateString: {
      month: null,
      date: null,
      day: null
    },

    tableItems: [],
    absentStudents: [],
    edittedTime: null,
    singleRowData: null,

    tableHeaders: [
      {
        text: 'FIRST NAME',
        value: 'firstName',
        align: 'start'
      },
      {
        text: 'MIDDLE NAME',
        value: 'middleName'
      },
      {
        text: 'LAST NAME',
        value: 'lastName'
      },
      {
        text: 'MORNING',
        value: 'morning'
      },
      {
        text: 'AFTERNOON',
        value: 'afternoon'
      },
      {
        text: 'ACTIONS',
        value: 'actions'
      }
    ]
  }),

  computed: {
    ...mapState('students', {
      students: state => state.allStudents
    }),
    ...mapState('attendance', {
      existingAttendance: state => state.existingAttendance,
      discardAttendance: state => state.discardAttendance,
      snackbars: state => state.snackbars,
    }),
    ...mapState('scanCode', {
      decodedText: state => state.decodedText,
    }),
    ...mapState('gamify', {
      bestAttendees: state => state.bestAttendees,
    }),
  },

  methods: {
    ...mapActions('students', ['getAllStudents', 'updateStudentDetails']),
    ...mapActions('attendance', ['getExistingAttendance', 'discardNewAttendance', 'saveNewAttendance', 'messageGuardian', 'messageGuardians']),
    ...mapActions('scanCode', ['setScannedText', 'emptyDecodedText']),
    ...mapActions('gamify', ['updatePoints', 'getPerfectAttendanceAward', 'resetPerfectAttendanceAward']),

    createAttendance: function () {
      if (this.existingAttendance.length === 0 && this.newAttendanceForm.date !== null) { //When the selected date doesn't have an existing attendance yet.
        console.log('bad2');
        console.log(this.students);
        this.students.forEach(student => {
          this.tableItems.push({
            reference: student.reference,
            firstName: student.firstName.toUpperCase(),
            lastName: student.lastName.toUpperCase(),
            middleName: student.middleName.toUpperCase(),
            morning: '--',
            afternoon: '--',
            apiKey: student.apiKey
          })
        })
        localStorage.setItem('attendance', JSON.stringify(this.tableItems))
        localStorage.setItem('attendance-date', JSON.stringify({
          date: this.newAttendanceForm.date,
          time: this.newAttendanceForm.time
        }))
      } else if (this.existingAttendance.length !== 0) { //When there's already an existing attendance on the selected date.
        console.log('bad');
        console.log(this.existingAttendance);
        this.students.forEach(student => {
          this.existingAttendance.forEach(data => {
            if (student.apiKey === data.apiKey) {
              student = data
            }
          })
          if (student.morning === null || student.morning === undefined) {
            student.morning = '--'
          }
          if (student.afternoon === null || student.afternoon === undefined) {
            student.afternoon = '--'
          }
          this.tableItems.push({
            reference: student.reference,
            firstName: student.firstName.toUpperCase(),
            lastName: student.lastName.toUpperCase(),
            middleName: student.middleName.toUpperCase(),
            morning: student.morning,
            afternoon: student.afternoon,
            apiKey: student.apiKey
          })
        })

        localStorage.setItem('attendance', JSON.stringify(this.tableItems))
        localStorage.setItem('attendance-date', JSON.stringify({
          date: this.newAttendanceForm.date,
          time: this.newAttendanceForm.time
        }))

      }

      this.stringifyDate()
      this.dialogs.attendance = false
    },

    notifyGuardian: function () {
      let message = ''
      const attendanceDate = JSON.parse(localStorage.getItem('attendance-date'))
      const dateArray = attendanceDate.date.split('-')
      let arrivedTime = this.scannedStudent.arrivedTime.split(':').join('%3A')
      const studentName = this.scannedStudent.name.split(' ').join('%20') //Encode the student's name

      if (this.newAttendanceForm.time === 'Morning') {
        message = `Magandang%20Umaga%21%0A%0AAng%20iyong%20anak%20na%20si%20${studentName}%20ay%20nasa%20eskwelahan%20ngayong%20${this.attendanceDateString.month}%20${dateArray[2]}%20sa%20oras%20na%20${arrivedTime}%20ng%20umaga.`
      } else {
        message = `Magandang%20Hapon%21%0A%0AAng%20klase%20ng%20iyong%20anak%20na%20si%20${studentName}%20ngayong%20${this.attendanceDateString.month}%20${dateArray[2]}%20ay%20natapos%20sa%20oras%20na%20${arrivedTime}%20ng%20hapon.%20Ang%20mga%20klase%20para%20sa%20araw%20na%20ito%20ay%20tapos%20na%20at%20ang%20mga%20estudyante%20ay%20maaari%20ng%20umuwi.`
      }

      this.messageGuardian({
        apiKey: this.decodedText,
        message: message
      })
      this.emptyDecodedText()

      this.dialogs.scanSuccess = false
    },

    checkAbsents() {
      this.absentStudents = []
      const attendanceDate = JSON.parse(localStorage.getItem('attendance-date'))

      if (attendanceDate.time === 'Morning') {
        this.tableItems.forEach(item => {
          if (item.morning === '--') {
            this.absentStudents.push(item)
          }
        })
      } else {
        this.tableItems.forEach(item => {
          if (item.afternoon === '--') {
            this.absentStudents.push(item)
          }
        })
      }
      return this.absentStudents.length !== 0 ? true : false
    },

    // saveAttendace: function () {
    //   this.checkBestAttendees()
    // },

    saveAttendace: async function () {
      this.isPageLoaded = false

      console.log('SAVED');
      const attendanceDate = JSON.parse(localStorage.getItem('attendance-date'))

      await this.saveNewAttendance({
        name: attendanceDate.date,
        date: {
          month: this.attendanceDateString.month,
          day: this.attendanceDateString.day,
          date: this.attendanceDateString.date
        },
        attendances: this.tableItems,
        absents: this.absentStudents
      })

      this.checkBestAttendees()

      //Updates students' points
      this.students.forEach(student => {
        this.tableItems.forEach(item => {

          if (student.reference.toString() === item.reference.toString()) {
            console.log(student.reference + ':' + item.reference);
            if (attendanceDate.time === "Morning") {
              if (item.morning !== '--') {
                this.updatePoints({
                  reference: student.reference,
                  points: student.points + 1
                })
              }
            } else {
              if (item.afternoon !== '--') {
                console.log(student.firstName + ':' + item.firstName + '->' + item.afternoon);
                this.updatePoints({
                  reference: student.reference,
                  points: student.points + 1
                })
              }
            }
          }
        })
      })

      //Notify guardians of all absent students
      if (this.absentStudents.length !== 0) {
        const attendanceDate = JSON.parse(localStorage.getItem('attendance-date'))
        const dateArray = attendanceDate.date.split('-')
        let time = `${new Date().getHours() > 12 ? new Date().getHours() - 12 : new Date().getHours()}%3A${new Date().getMinutes().toString().length === 1 ? '0' + new Date().getMinutes() : new Date().getMinutes()}`
        let message = ''

        if (JSON.parse(localStorage.getItem('attendance-date')).time === 'Morning') {
          message = `Magandang%20Umaga%21%0A%0ANais%20naming%20ipaalam%20na%20ang%20inyong%20anak%20ay%20wala%20sa%20klase%20ngayong%20${this.attendanceDateString.month}%20${dateArray[2]}%20sa%20oras%20na%20${time}%20ng%20umaga.%20Makatatanggap%20kayo%20ng%20bagong%20mensahe%20kung%20ang%20inyong%20anak%20ay%20nahuli%20lamang%20sa%20klase.`
        } else {
          message = `Magandang%20Hapon%21%0A%0ANais%20naming%20ipaalam%20na%20ang%20inyong%20anak%20ay%20wala%20sa%20klase%20ngayong%20${this.attendanceDateString.month}%20${dateArray[2]}%20sa%20oras%20na%20${time}%20ng%20hapon.%20Ang%20mga%20klase%20para%20sa%20araw%20na%20ito%20ay%20tapos%20na%20at%20ang%20mga%20estudyante%20ay%20maaari%20ng%20umuwi.`
        }

        await this.messageGuardians({
          absentStudents: this.absentStudents,
          message: message
        })

      }

      localStorage.removeItem('attendance')
      localStorage.removeItem('attendance-date')
      this.tableItems = []
      this.dialogs.absents = false
      this.absentStudents = []
      this.isPageLoaded = true
    },

    checkBestAttendees: function () {
      const attendanceDate = JSON.parse(localStorage.getItem('attendance-date'))
      let month = new Date().toLocaleString('default', { month: 'long' })
      if (month === this.bestAttendees.currentMonth) {
        //Updates list of qualified students for the monthly best in attendance award
        this.tableItems.forEach(student => {
          this.bestAttendees.awardees.forEach(awardee => {

            if (attendanceDate.time === "Morning") {

              if (awardee.reference.toString() === student.reference.toString()) {

                if (student.morning === '--') {
                  let index = this.bestAttendees.awardees.indexOf(awardee)
                  if (index > -1) {
                    this.bestAttendees.awardees.splice(index, 1)
                  }
                } else {
                  awardee.streak = awardee.streak + 1
                }

              }
            } else {
              if (awardee.reference.toString() === student.reference.toString()) {

                if (student.afternoon === '--') {
                  let index = this.bestAttendees.awardees.indexOf(awardee)
                  if (index > -1) {
                    this.bestAttendees.awardees.splice(index, 1)
                  }
                } else {
                  awardee.streak = awardee.streak + 1
                }

              }
            }
          })
        })
      } else {

        //Adds the badge, and reward points to the total points of qualified students for the month
        let awardBadge = ''
        this.badges.forEach(badge => {
          if (this.bestAttendees.currentMonth.toLowerCase() === badge.month) {
            awardBadge = badge.name
          }
        })

        this.students.forEach(student => {
          this.bestAttendees.awardees.forEach(awardee => {
            if (student.reference.toString() === awardee.reference.toString()) {
              student.points = Number(student.points) + Number(this.bestAttendees.rewardPoints)
              student.badges.push(this.bestAttendees.currentMonth.toLowerCase())
              student.awardNotice.push({
                month: this.bestAttendees.currentMonth.toLowerCase(),
                badge: awardBadge
              })
            }
          })
          console.log(student.badges);
          this.updateStudentDetails(student)
        })

        //Resets qualified students for the monthly best in attendance award
        this.bestAttendees.awardees = []

        this.tableItems.forEach(student => {
          if (attendanceDate.time === "Morning") {
            if (student.morning !== '--') {
              this.bestAttendees.awardees.push({
                reference: Number(student.reference),
                status: "not awarded",
                streak: 1
              })
            }
          } else {
            if (student.afternoon !== '--') {
              this.bestAttendees.awardees.push({
                reference: Number(student.reference),
                status: "not awarded",
                streak: 1
              })
            }
          }
        })
      }

      this.resetPerfectAttendanceAward({ awardees: this.bestAttendees.awardees, currentMonth: month })
      console.log(this.bestAttendees);
    },

    editRow: function (rowData) {
      this.singleRowData = rowData
      this.dialogs.edit = true
    },

    saveNewTime: function () {
      const attendanceDate = JSON.parse(localStorage.getItem('attendance-date'))
      this.tableItems.forEach(item => {
        if (attendanceDate.time === 'Morning') {
          if (item.apiKey === this.singleRowData.apiKey) {
            item.morning = this.edittedTime + ' AM'
          }
        } else {
          if (item.apiKey === this.singleRowData.apiKey) {
            let timeArray = this.edittedTime.split(':')
            timeArray[0] > 12 ? timeArray[0] = `0${timeArray[0] - 12}` : timeArray[0]
            item.afternoon = timeArray.join(':') + ' PM'
          }
        }
      })
      localStorage.setItem('attendance', JSON.stringify(this.tableItems))
      this.dialogs.edit = false
    },

    clearTime: function () {
      const attendanceDate = JSON.parse(localStorage.getItem('attendance-date'))
      this.tableItems.forEach(item => {
        if (attendanceDate.time === 'Morning') {
          if (item.apiKey === this.singleRowData.apiKey) {
            item.morning = '--'
          }
        } else {
          if (item.apiKey === this.singleRowData.apiKey) {
            item.afternoon = '--'
          }
        }
      })
      localStorage.setItem('attendance', JSON.stringify(this.tableItems))
      this.dialogs.edit = false
    },

    stringifyDate: function () {
      //Changes the date format from 0000-00-00 to string (e.g. July, Friday, etc.) for saving the attendance
      const attendanceDate = JSON.parse(localStorage.getItem('attendance-date'))
      const dateArray = attendanceDate.date.split('-')
      const date = new Date(dateArray[0], dateArray[1] - 1, dateArray[2])
      this.attendanceDateString.month = date.toLocaleString('default', { month: 'long' }) //Formats the month as string
      this.attendanceDateString.date = dateArray[2]
      this.attendanceDateString.day = date.toLocaleString('default', { weekday: 'long' }) //Formats the day as string
    }
  },

  mounted: async function() {
    await this.getAllStudents()
    await this.getPerfectAttendanceAward()

    if (localStorage.getItem('attendance') && JSON.parse(localStorage.getItem('attendance')).length !== 0) {
      this.tableItems = JSON.parse(localStorage.getItem('attendance')) //Gets the unsaved attendance from the localStorage
      this.stringifyDate()
      this.dialogs.attendance = false
    }

    this.isPageLoaded = true
  },

  watch: {
    newAttendanceForm: {
      handler(newValue, oldValue) {
        this.getExistingAttendance(newValue.date)
      },
      deep: true
    },

    decodedText(newText, oldText) {
      if (newText !== null) {
        console.log('decoded text not empty')
        const time = new Date()
        let scanTime = '--'

        this.students.forEach(student => {
          if (student.apiKey === newText) { //Finds student who matched the scanned apiKey.

            this.tableItems.forEach(data => { //Finds the same student in the attendance array.
              let studentInStudents = `${student.lastName.toUpperCase()}, ${student.firstName.toUpperCase()} ${student.middleName.toUpperCase()}`
              let studentInAttendance = `${data.lastName}, ${data.firstName} ${data.middleName}`

              if (studentInStudents === studentInAttendance) { // Changed the respective time.

                scanTime = `${time.getHours() > 12 ? time.getHours() - 12 : time.getHours()}:${time.getMinutes().toString().length === 1 ? '0' + time.getMinutes() : time.getMinutes()}`
                this.dialogs.scanSuccess = true
                this.scannedStudent.name = studentInAttendance
                this.scannedStudent.arrivedTime = scanTime

                if (JSON.parse(localStorage.getItem('attendance-date')).time === 'Morning') {
                  data.morning = `${scanTime} AM`
                } else {
                  data.afternoon = `${scanTime} PM`
                }
              }
            })
          }
        })

        console.log(this.scannedStudent);
        localStorage.setItem('attendance', JSON.stringify(this.tableItems)) //Updates the attendance in the localStorage
      }
    },

    //TO DO: Change this to emit event
    discardAttendance(newValue, oldValue) {
      console.log('discardAttendance: ', newValue);
      if (newValue === true) {
        console.log('called when discard true');
        this.tableItems = []
        // this.dialogs.attendance = true
        this.discardNewAttendance(false)
      }
    }
  }
};

</script>

<style scoped>
#check-attendance-title {
  color: #0D47A1;
}
</style>
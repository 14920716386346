<template>
  <div>
    <v-app-bar app dense :color="navColor" v-if="hideNavigation">
      <v-app-bar-nav-icon @click="drawer = !drawer" color="white"></v-app-bar-nav-icon>
      <v-app-bar-title class="white--text text-subtitle-1 pl-0">CHRONO<span class="font-weight-bold">CHECK</span></v-app-bar-title>
      <v-spacer></v-spacer>
      <v-img src="../assets/eclipse.png" contain max-height="35" max-width="35" @click="redirectToBadges()"></v-img>
    </v-app-bar>

    <!-- Contains navigation buttons inside the drawer -->
    <v-navigation-drawer app temporary v-model="drawer" :color="navColor">
      <v-list-item>
        <v-list-item-icon class="mr-2">
          <v-img src="../assets/eclipse.png" width="50"></v-img>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-weight-light white--text text-h6">CHRONO<span
              class="font-weight-bold">CHECK</span></v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Loop through the navButtons and create a button on each loop -->
      <v-list>
        <v-list-item v-for="(item, index) in navButtons" :key="index" v-if="currentUser && currentUser.permissions.includes(item.name.toLowerCase())" :to="{name: item.route}">
          <v-list-item-icon>
            <v-icon color="white">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text text-overline">{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-divider></v-divider>
        <div class="pa-4">
          <v-btn outlined block color="white" class="text-overline" @click="signOutUser()">
            <v-icon color="white" class="mr-2">mdi-logout</v-icon>
            Log out
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Navigation',

  data: () => ({
    currentUser: null,
    drawer: false,
    navColor: '#0D47A1',
    navButtons: [
      {
        name: 'Dashboard',
        route: 'Dashboard',
        icon: 'mdi-view-dashboard',
      },
      {
        name: 'Scan Code',
        route: 'Scan Code',
        icon: 'mdi-qrcode-scan',
      },
      {
        name: 'Students',
        route: 'Students',
        icon: 'mdi-account-group',
      },
      {
        name: 'Profile',
        route: 'Profile',
        icon: 'mdi-account',
      },
      {
        name: 'Attendance',
        route: 'Attendance',
        icon: 'mdi-account-check',
      },
      {
        name: 'Gamify',
        route: 'Gamify',
        icon: 'mdi-gamepad-right',
      }
    ]
  }),

  methods: {
    ...mapActions('authentication', ['signInUser', 'signOutUser']),
    redirectToBadges: function () {
      //This feature is completely unnecessary but why not?
      if(this.$route.name !== 'Achievements'){
        this.$router.push({name: 'Achievements'})
      }
    }
  },

  computed: {
    ...mapState('authentication', {
      user: state => state.user
    }),

    hideNavigation () {
      //hides navigation on login and developers routes
      const currentRoute = this.$route.name

      if(currentRoute === 'Login' || currentRoute === 'Developers'){
        return false
      }else{
        return true
      }
    }
  },

  mounted() {
    this.currentUser = JSON.parse(localStorage.getItem('user'))
  },

  watch: {
    user: {
      handler(newValu, oldValue){
        this.currentUser = JSON.parse(localStorage.getItem('user'))
      },
      deep: true
    }
  }
};

</script>

<style scoped></style>
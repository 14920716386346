<template>
  <v-container>
    <Loading v-if="!isPageLoaded" />

    <div v-if="isPageLoaded">
      <p class="mt-4 text-h6 font-weight-bold" id="personal-info-title">Student's Personal Information</p>

      <!-- Rewards and Achievements -->
      <v-row>
        <v-col cols="12" md="3" class="order-2 order-md-1">
          <v-divider class="mb-2" color="#0D47A1"></v-divider>
          <p class="text-subtitle-2 d-flex align-start">
            <v-icon small color="#0D47A1" class="mr-2">mdi-gift</v-icon> Rewards
          </p>

          <v-row dense v-if="myRewards.length !== 0">
            <v-col cols="12" v-for="(reward, index) in myRewards" :key="index">
              <v-card outlined flat class="d-flex py-2">
                <v-img id="rewardPhoto" :src="require('../../assets/rewards/' + reward.image + '.png')" contain
                  max-width="50" max-height="50" class="ml-2 mr-2"></v-img>

                <div style="width: 100%;" class="d-flex flex-column justify-space-between">
                  <p class="text-subtitle-2 font-weight-bold mb-0">{{ reward.name }}</p>
                  <v-card-text class="pa-0 d-flex align-center ma-0">
                    <p class="text-caption ma-0">{{ reward.points }} points</p>
                    <!-- <v-btn x-small outlined rounded class="red--text my-0 mr-2 ml-auto">Delete</v-btn> -->
                    <!-- <v-btn x-small outlined rounded class="green--text my-0 mr-2 ml-auto">Redeem</v-btn> -->
                  </v-card-text>
                </div>
              </v-card>
            </v-col>
          </v-row>

          <v-row v-else>
            <v-col cols="12">
              <v-card flat id="no-reward">
                <v-card-text class="font-italic text-caption">
                  Collect points and claim rewards to get an edge in your class!!
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-divider class="mt-10 mb-2" color="#0D47A1"></v-divider>
          <p class="text-subtitle-2 d-flex align-start">
            <v-icon small color="#0D47A1" class="mr-2">mdi-trophy-variant</v-icon> Achievements
          </p>

          <v-row v-if="myBadges" class="mb-4">
            <v-col cols="3" v-for="(badge, index) in myBadges" :key="index">
              <v-card flat>
                <v-img :src="require('../../assets/badges/' + badge + '.png')"></v-img>
              </v-card>
            </v-col>
          </v-row>

          <v-row v-else>
            <v-col cols="12">
              <v-card flat id="no-achievement">
                <v-card-text class="font-italic text-caption">
                  Keep your attendance streak up and make the next badge yours!!
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <!-- Attendance table -->
        <v-col cols="12" md="6" class="order-3 order-md-2">
          <v-card flat>
            <Table :headers="tableHeaders" :items="tableItems" :isPrimary="false" :isSecondary="false" />
          </v-card>
        </v-col>

        <!-- Displays the personal informations of a student -->
        <v-col cols="12" md="3" class="order-1 order-md-3">
          <v-card flat color="#0D47A1" class="d-flex pa-2" v-if="student !== null">
            <v-img src="../../assets/eclipse.png" contain max-height="75" max-width="75"></v-img>
            <v-card-text class="pa-0 ma-auto white--text">
              <v-card-title class="py-0 ma-auto font-weight-bold">{{ student.lastName.toUpperCase() }}</v-card-title>
              <v-card-subtitle class="py-0 ma-auto">{{ student.firstName }} {{ student.middleName }}</v-card-subtitle>
            </v-card-text>
          </v-card>

          <v-card flat class="mt-2" v-if="student !== null">
            <v-card-text>
              <p><span class="font-weight-bold">GENDER: </span>{{ student.gender.toUpperCase() }}</p>
              <p><span class="font-weight-bold">AGE: </span>{{ student.age }}</p>
              <p><span class="font-weight-bold">GUARDIAN: </span>{{ student.guardian.toUpperCase() }}</p>
              <p><span class="font-weight-bold">GUARDIAN'S NO.: </span>{{ student.guardianContact }}</p>
              <p><span class="font-weight-bold">REFERENCE NO.: </span>{{ student.reference }}</p>
              <p><span class="font-weight-bold">REWARD POINTS: </span>{{ student.points }}</p>
            </v-card-text>
          </v-card>

          <!-- Dialog for best in attendance award -->
          <v-dialog v-if="noticeOfAward && currentStudent !== null" v-model="dialogs.award" max-width="500"
            max-height="500" persistent>
            <v-card class="pa-4">
              <v-card-title><span class="mx-auto" style="color: #0D47A1;">CONGRATULATIONS</span></v-card-title>
              <v-card-text class="text-center">
                Due to your dedication and perseverance, we award you {{ noticeOfAward.length > 1 ? 'these' : 'this'
                }}
              </v-card-text>

              <v-card flat v-for="(award, index) in noticeOfAward" :key="index">
                <v-img contain max-width="250" class="mx-auto"
                  :src="require('../../assets/badges/' + award.month + '.png')"></v-img>

                <v-card-text class="text-center pt-0 mb-4 mt-0">
                  <v-card-subtitle class="pb-0 text-subtitle-1 text-md-h5 font-weight-bold">{{ award.badge.toUpperCase()
                  }}
                    BADGE</v-card-subtitle>
                </v-card-text>
              </v-card>

              <v-card-text class="text-center">
                <!-- The number of badge multiply equivalent reward points per badge to get total reward points -->
                And {{ myAchievements ? myAchievements.rewardPoints * noticeOfAward.length : 100 *
                  noticeOfAward.length }} reward points that you may use to claim incentive tickets. Always be in
                class and aim to collect all
                badges. Congratulations!
              </v-card-text>

              <v-btn block outlined class="mt-2" color="red" @click="dialogs.award = false; readAwardNotice()">
                <v-icon class="mr-2">mdi-close</v-icon>
                CLOSE
              </v-btn>
            </v-card>
          </v-dialog>

          <v-snackbar v-model="snackbar.isSnackbar" :timeout="snackbar.timeout" top color="success">
            {{ snackbar.text }}
          </v-snackbar>

          <v-btn block class="mt-2 white--text" color="#1976D2" @click="generateQrCode()" :loading="loading">
            <v-icon class="mr-2">mdi-download</v-icon>
            GENERATE QR CODE
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
  
<script>
import Table from '../../components/Table.vue'
import { mapActions, mapState } from 'vuex'
import Loading from '../../components/Loading.vue'

export default {
  name: 'Profile',

  components: {
    Table,
    Loading
  },

  data: () => ({
    isPageLoaded: false,
    currentStudent: null,
    loading: false,
    dialogs: {
      update: false,
      award: true
    },

    genderOptions: ['Male', 'Female'],

    snackbar: {
      isSnackbar: false,
      text: 'Details updated successfully.',
      timeout: 3000
    },

    tableHeaders: [
      {
        text: 'MONTH',
        value: 'month',
        align: 'start'
      },
      {
        text: 'DATE',
        value: 'date'
      },
      {
        text: 'DAY',
        value: 'day'
      },
      {
        text: 'MORNING',
        value: 'morning'
      },
      {
        text: 'AFTERNOON',
        value: 'afternoon'
      }
    ]
  }),

  computed: {
    ...mapState('students', {
      student: state => state.singleStudent,
    }),

    ...mapState('attendance', {
      attendance: state => state.singleAttendance,
      attendances: state => state.allAttendances,
    }),

    ...mapState('gamify', {
      allRewards: state => state.allRewards,
      allAchievements: state => state.allAchievements
    }),

    tableItems() {
      let singleStudentAttendance = []

      this.attendances.forEach(attendance => {
        if (attendance.reference.toString() === this.student.reference.toString()) {
          let dateArray = attendance.date.split('-')
          let data = new Date(dateArray[0], dateArray[1] - 1, dateArray[2])
          let month = data.toLocaleString('default', { month: 'long' })
          let weekday = data.toLocaleString('default', { weekday: 'long' })

          singleStudentAttendance.push({
            month: month,
            date: dateArray[2],
            day: weekday,
            morning: attendance.morning,
            afternoon: attendance.afternoon
          })
        }
      })
      console.log(singleStudentAttendance)
      return singleStudentAttendance
    },

    myRewards() {
      let rewards = []

      if (this.student) {
        if (this.student.rewards.length !== 0) {
          this.student.rewards.forEach(myReward => {
            this.allRewards.forEach(reward => {
              if (myReward.toString() === reward.id.toString()) {
                rewards.push(reward)
              }
            })
          })
        }
      }
      return rewards
    },

    myAchievements() {
      if (this.allAchievements[0] === undefined || this.allAchievements[0] === null) {
        return false
      } else {
        return this.allAchievements[0]
      }
    },

    myBadges() {
      if (this.student === undefined || this.student === null || this.student.badges.length === 0) {
        console.log('nothingness')
        return false
      } else {
        console.log(this.student)
        return this.student.badges
      }
    },

    noticeOfAward() {
      if (this.student === undefined || this.student === null || this.student.awardNotice.length === 0) {
        console.log('nothingness')
        return false
      } else {
        console.log(this.student)
        return this.student.awardNotice
      }
    }
  },

  methods: {

    ...mapActions('students', ['updateStudentDetails', 'getSingleStudent', 'getSingleAttendance']),
    ...mapActions('attendance', ['getSingleAttendance', 'getAllAttendance']),
    ...mapActions('gamify', ['getAllRewards', 'getAllAchievements', 'emptyAwardNotice']),

    generateQrCode: function () {
      this.loading = true
      let color = '0D47A1'
      let bgColor = 'FFFFFF'
      let url = `https://api.qrserver.com/v1/create-qr-code/?data=${this.student.apiKey}&size=500x500&color=${color}&bgcolor=${bgColor}`
      let filename = `${this.student.lastName}_${this.student.firstName}.png`

      fetch(url)
        .then(res => res.blob())
        .then(data => {
          var anchor = document.createElement("a") //creates an anchor tag/element
          anchor.href = window.URL.createObjectURL(data)
          anchor.download = filename; //force download of qr code
          anchor.click()

          window.URL.revokeObjectURL(url) //removes generated link for qr code download
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          console.log(error)
        })

    },

    readAwardNotice: function () {
      this.emptyAwardNotice(this.student.reference)
    }
  },

  mounted: async function() {
    this.currentStudent = JSON.parse(localStorage.getItem('user'))

    await this.getSingleStudent(this.currentStudent.reference)
    await this.getSingleAttendance(this.currentStudent.reference)
    await this.getAllRewards()
    await this.getAllAchievements()
    await this.getAllAttendance()

    this.isPageLoaded = true
  }
};

</script>
  
<style scoped>
#personal-info-title {
  color: #0D47A1;
}

#rewardPhoto {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  /* background-color: #0D47A1; */
}
</style>
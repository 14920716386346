<template>
  <v-container fluid id="login-container" class="d-flex flex-column justify-space-between">
    <v-card width="400" flat class="mx-auto mt-10">
      <v-img src="../../assets/eclipse.png" contain max-width="125" max-height="125" class="mx-auto"></v-img>
      <v-card-title>
        <p class="mx-auto mb-0 font-weight-bold" style="color: #0D47A1;">CHRONOCHECK</p>
      </v-card-title>
      <v-card-subtitle class="text-center">Attendance Monitoring System</v-card-subtitle>

      <v-text-field outlined label="EMAIL" v-model="userCredentials.email" class="mt-10"></v-text-field>
      <v-text-field outlined label="PASSWORD" v-model="userCredentials.password"></v-text-field>
      <v-btn block color="#0D47A1" class="white--text" @click="logIn()" :loading="loading.isLoading">
        <v-icon class="mr-2">mdi-login</v-icon>
        LOG IN
      </v-btn>
      <v-card-subtitle class="text-center red--text">{{loading.errorMessage}}</v-card-subtitle>
    </v-card>
    <div class="d-flex" @click="redirect()">
      <v-icon color="#0D47A1">mdi-facebook</v-icon>
      <p class="text-overline my-auto ml-2" style="color: #0D47A1;">Developers</p>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Login',

  data: () => ({
    userCredentials: {
      email: null,
      password: null,
    }
  }),
  computed: {
    ...mapState('authentication', {
      loading: state => state.loading
    })
  },

  methods: {
    ...mapActions('authentication', ['signInUser', 'signOutUser']),
    redirect: function () {
      this.$router.push({ name: 'Developers' })
    },

    logIn: async function () {
      await this.signInUser(this.userCredentials)
    }
  }
};

</script>

<style scoped>
#login-container {
  height: 100vh;
}
</style>
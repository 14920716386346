import Gamify from '../../views/Gamify/Gamify'

export default [
    {
        path: '/gamify',
        name: 'Gamify',
        component: Gamify,
        meta: {
            isAuthenticated: true,
            permissions: ['DEVELOPER', 'INSTRUCTOR', 'STUDENT']
        }
    },
]
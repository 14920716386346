import Vue from 'vue'
import Vuex from 'vuex'
import { collection, addDoc, doc, setDoc, getDocs, getDoc, deleteDoc, updateDoc } from "firebase/firestore";
import { db } from '../../firebase'
// import loading from '../../store/loading'

Vue.use(Vuex)

export const attendance = {
  namespaced: true,
  state: {
    allAttendances: [],
    singleAttendance: [],
    existingAttendance: [],
    attendanceDates: [],
    discardAttendance: false,
    snackbars: {
      save: {
        isSnackbar: false,
        isSuccess: false,
        message: null,
        timeout: 3000
      }
    },
    loading: {
      isLoading: false,
      message: ''
    }
  },
  getters: {
    getAttendances: state => state.allAttendances,
    getAttendance: state => state.singleAttendance
  },
  mutations: {
    SET_ATTENDANCES: (state, payload) => state.allAttendances = payload,
    SET_SINGLE_ATTENDANCE: (state, payload) => state.singleAttendance = payload,
    SET_EXISTING_ATTENDANCE: (state, payload) => state.existingAttendance = payload,
    SET_DISCARD_ATTENDANCE: (state, payload) => state.discardAttendance = payload,
    SET_ATTENDANCE_DATES: (state, payload) => state.attendanceDates = payload,
    SET_STATUS_MESSAGE: (state, payload) => {
      if (payload.snackbar === 'save') {
        state.snackbars.save.isSnackbar = payload.isSnackbar
        state.snackbars.save.message = payload.message
        state.snackbars.save.isSuccess = payload.isSuccess
      }
    },
    SET_LOADING: (state, payload) => {
      state.loading.isLoading = payload.isLoading
      state.loading.message = payload.message
    }
  },
  actions: {
    async getAllAttendance({ commit }) {
      // let students = []
      let attendances = []

      // Get all attendances in collection "attendances"
      const querySnapshotDate = await getDocs(collection(db, "attendances"));
      querySnapshotDate.forEach(async (attendanceDate) => {
        const querySnapshotAttendance = await getDocs(collection(db, "attendances", attendanceDate.id, "students"))
        querySnapshotAttendance.forEach((doc) => {
          attendances.push({
            date: attendanceDate.id,
            reference: doc.id,
            name: `${doc.data().lastName}, ${doc.data().firstName} ${doc.data().middleName}`,
            morning: doc.data().morning,
            afternoon: doc.data().afternoon,
            apiKey: doc.data().apiKey
          })
        })
      })


      // //Get all attendance of each students in the attendance subcollection
      // students.forEach(async student => {
      //   const querySnapshotSubcollection = await getDocs(collection(db, "students", student.reference.toString(), "attendance"));
      //   querySnapshotSubcollection.forEach((doc) => {
      //     // doc.data() is never undefined for query doc snapshots
      //     attendances.push({
      //       name: `${student.firstName.toUpperCase()} ${student.lastName.toUpperCase()}`,
      //       month: doc.data().month.toUpperCase(),
      //       day: doc.data().day.toUpperCase(),
      //       date: doc.data().date,
      //       morning: doc.data().morning + ' AM',
      //       afternoon: doc.data().afternoon + ' PM'
      //     })
      //     console.log(doc.id, " => ", doc.data());
      //   })
      // })

      commit('SET_ATTENDANCES', attendances)
    },

    async getAttendanceDates({ commit }) {
      commit('SET_LOADING', { isLoading: true, message: 'Fetching data, please wait...' })
      let attendanceDates = []

      // Get all attendances in collection "attendances"
      const querySnapshotDate = await getDocs(collection(db, "attendances"));
      querySnapshotDate.forEach(async (attendanceDate) => {
        attendanceDates.push(attendanceDate.id) //attendances' dates
      })

      commit('SET_ATTENDANCE_DATES', attendanceDates)
      commit('SET_LOADING', { isLoading: false, message: '' })
    },

    async getSingleAttendance({ commit }, data) {
      let attendance = []

      // Get all attendance of a single student in the attendance subcollection within students collection
      const querySnapshot = await getDocs(collection(db, "students", data.toString(), "attendance"));
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        attendance.push({
          month: doc.data().month.toUpperCase(),
          day: doc.data().day.toUpperCase(),
          date: doc.data().date,
          morning: doc.data().morning + ' AM',
          afternoon: doc.data().afternoon + ' PM'
        })
        console.log(doc.id, " => ", doc.data());
      })

      commit('SET_SINGLE_ATTENDANCE', attendance)
    },

    async getExistingAttendance({ commit }, data) {
      let attendance = []

      // Get all students' attendances in the students subcollection within attendance collection
      const querySnapshot = await getDocs(collection(db, "attendances", data.toString(), "students"));
      // const querySnapshot = await getDocs(collection(db, "attendances", "attendance1", "students"));
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        attendance.push({
          reference: doc.id,
          firstName: doc.data().firstName.toUpperCase(),
          lastName: doc.data().lastName.toUpperCase(),
          middleName: doc.data().middleName.toUpperCase(),
          morning: doc.data().morning,
          afternoon: doc.data().afternoon,
          apiKey: doc.data().apiKey
        })
        // attendance.push(doc.data())
        console.log(doc.id, " => ", doc.data());
      })

      console.log('good');

      commit('SET_EXISTING_ATTENDANCE', attendance)
    },

    async saveNewAttendance({ commit }, data) {
      commit('SET_STATUS_MESSAGE', { message: null, isSnackbar: false, isSuccess: false, snackbar: 'save' })
      commit('SET_LOADING', { isLoading: true, message: 'Saving, please wait...' })

      // let attendanceDates = []
      let attendanceCountLimit = 7

      // Get all students' attendances in the attendance collection
      const attendanceDates = await getDocs(collection(db, "attendances"));
      console.log(attendanceDates.docs.length);
      // querySnapshot.forEach((doc) => {
      //   attendanceDates.push({
      //     reference: doc.id,
      //   })
      // })

      //Deletes the oldest attendance when saved attendance reaches 7
      if (attendanceDates.docs.length >= attendanceCountLimit) {
        // Get all students' attendances in the students subcollection within attendance collection
        const querySnapshot = await getDocs(collection(db, "attendances", attendanceDates.docs[0].id, "students"));
        // const querySnapshot = await getDocs(collection(db, "attendances", "attendance1", "students"));
        querySnapshot.forEach(async (data) => {
          await deleteDoc(doc(db, "attendances", attendanceDates.docs[0].id, "students", data.id));
        })

        await deleteDoc(doc(db, "attendances", attendanceDates.docs[0].id));
      }

      try {
        await setDoc(doc(db, "attendances", data.name), data.date)
        data.attendances.forEach(async attendance => {
          setDoc(doc(db, "attendances", data.name, "students", attendance.reference.toString()), {
            firstName: attendance.firstName,
            middleName: attendance.middleName,
            lastName: attendance.lastName,
            morning: attendance.morning,
            afternoon: attendance.afternoon,
            apiKey: attendance.apiKey
          })
        })

        if (data.absents.length === 0) {
          commit('SET_LOADING', { isLoading: false, message: '' })
          commit('SET_STATUS_MESSAGE', { message: 'Attendance has been saved successfully.', isSnackbar: true, isSuccess: true, snackbar: 'save' })
        }

      } catch (error) {
        console.error("Error adding document: ", error)
        commit('SET_LOADING', { isLoading: false, message: '' })
        commit('SET_STATUS_MESSAGE', { message: 'Failed to save attendance. Try again.', isSnackbar: true, isSuccess: false, snackbar: 'save' })
      }

    },

    async updateSingleAttendance({ commit }, data) {
      try {
        console.log(data.afternoon);
        await updateDoc(doc(db, "attendances", data.date, "students", data.reference), { morning: data.morning, afternoon: data.afternoon });
      } catch (error) {
        console.log('attendance updateSingleAttendance error');
        console.log(error);
      }
    },

    messageGuardian({ commit }, data) {
      let url = `https://api.callmebot.com/facebook/send.php?apikey=${data.apiKey}&text=${data.message}` //Production
      // let url = `http://api.callmebot.com/facebook/send.php?apikey=${data.apiKey}&text=${data.message}` //Development

      fetch(url, { method: 'POST' })
        .then(res => {
          console.log(res.status)
        })
        .catch(error => {
          console.log(error)
        })
    },

    messageGuardians({ commit }, data) {
      // commit('SET_LOADING', { isLoading: true, message: 'Saving, please wait...' })
      const studentCount = data.absentStudents.length
      let index = 0
      let messageInterval = setInterval(() => {
        console.log(data.absentStudents);
        let url = `https://api.callmebot.com/facebook/send.php?apikey=${data.absentStudents[index].apiKey}&text=${data.message}` //Production
        // let url = `http://api.callmebot.com/facebook/send.php?apikey=${data.absentStudents[index].apiKey}&text=${data.message}` //Development

        fetch(url, { method: 'POST' })
          .then(res => {
            console.log(res.status)
          })
          .catch(error => {
            console.error(error)
          })

        index = index + 1

        if (index === studentCount) {
          commit('SET_LOADING', { isLoading: false, message: '' })
          commit('SET_STATUS_MESSAGE', { message: 'Attendance has been saved successfully.', isSnackbar: true, isSuccess: true, snackbar: 'save' })
          clearInterval(messageInterval)
        }
      }, 3000)
    },

    discardNewAttendance({ commit }, data) {
      commit('SET_DISCARD_ATTENDANCE', data)
    }
  },
  modules: {
    // loading
  }
}
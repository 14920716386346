<template>
    <div>
        <v-row dense>
            <v-col cols="12" sm="4" v-for="(riddle, index) in riddleItems" :key="index">
                <!-- <v-card outlined flat class="d-flex align-center">
                    <v-img :src="require('../../assets/cards/' + riddle.id + '.png')" max-width="75" max-height="75" contain
                        class="my-2 ml-2"></v-img>

                    <v-card-text class="py-0">
                        <p class="text-subtitle-2 font-weight-bold mb-2">{{ riddle.cardName.toUpperCase() }}</p>
                        <p class="text-body">50 points</p>
                    </v-card-text>
                </v-card> -->

                <v-card outlined flat class="d-flex py-2">
                    <v-img :src="require('../../assets/cards/' + riddle.id + '.png')" max-width="75" max-height="75" contain
                        class="mx-2"></v-img>

                    <div style="width: 100%;" class="d-flex flex-column justify-space-between my-1">
                        <p class="text-subtitle-2 font-weight-bold mb-0">{{ riddle.cardName.toUpperCase() }}</p>
                        <v-card-text class="pa-0 d-flex align-center ma-0">
                            <p class="text-caption ma-0">50 points</p>
                            <v-btn :disabled="isDisabled" v-if="!riddle.isAnswered" x-small outlined rounded class="green--text my-0 mr-2 ml-auto"
                                @click="selectRiddle(riddle)">Redeem</v-btn>
                            <v-btn v-else x-small plain disabled class="green--text my-0 mr-2 ml-auto">Answered</v-btn>
                        </v-card-text>
                    </div>
                </v-card>
            </v-col>
        </v-row>

        <!-- Dialog for purchasing riddle -->
        <v-dialog v-if="chosenRiddle !== null" v-model="dialogs.riddle" max-width="500" max-height="500" persistent>
            <v-card class="pa-4">
                <v-card-title><span class="mx-auto" style="color: #0D47A1;">PURCHASE RIDDLE</span></v-card-title>
                <v-card-text class="text-center">Would you like to buy this riddle?</v-card-text>

                <v-card outlined flat class="d-flex py-2 mb-6">
                    <v-img id="riddlePhoto" :src="require('../../assets/cards/' + `${chosenRiddle.id}` + '.png')" contain
                        max-width="75" max-height="75" class="ml-2 mr-2"></v-img>

                    <div style="width: 100%;" class="d-flex flex-column justify-space-between">
                        <p class="text-subtitle-2 font-weight-bold">{{ chosenRiddle !== null ?
                            chosenRiddle.cardName.toUpperCase() : '' }}
                        </p>
                        <v-card-text class="pa-0 d-flex align-center ma-0">
                            <p class="text-body ma-0">50 points</p>
                        </v-card-text>
                    </div>
                </v-card>

                <v-btn block class="mt-2 white--text" color="#0D47A1" @click="buyRiddle(chosenRiddle)">
                    <v-icon class="mr-2">mdi-shopping</v-icon>
                    BUY
                </v-btn>
                <v-btn block outlined class="mt-2" color="red" @click="dialogs.riddle = false; chosenRiddle = null">
                    <v-icon class="mr-2">mdi-close</v-icon>
                    CANCEL
                </v-btn>
            </v-card>
        </v-dialog>

        <!-- Snackbar for purchasing riddle -->
        <v-snackbar class="text-center" v-model="snackbars.buy.isSnackbar" :timeout="snackbars.buy.timeout" top
            :color="snackbars.buy.isSuccess ? 'success' : 'red accent-2'">
            {{ snackbars.buy.message }}
        </v-snackbar>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
    name: 'Riddle',

    props: [
        'riddles',
        'student'
    ],

    data: () => ({
        isDisabled: false,
        dialogs: {
            riddle: false
        },
        chosenRiddle: null,
    }),

    methods: {
        ...mapActions('gamify', ['purchaseRiddle']),
        selectRiddle: function (riddle) {
            this.dialogs.riddle = true
            console.log(riddle);
            this.chosenRiddle = riddle
        },

        buyRiddle: function (riddle) {
            this.isDisabled = true
            this.purchaseRiddle({
                reference: JSON.parse(localStorage.getItem('user')).reference,
                riddleId: riddle.id
            })
            this.dialogs.riddle = false
            console.log(riddle);
        },

    },

    computed: {
        ...mapState('gamify', {
            snackbars: state => state.snackbars,
        }),

        riddleItems() {
            let newRiddleArr = []
            console.log(this.student);
            this.riddles.forEach(riddle => {
                newRiddleArr.push({
                    isAnswered: false,
                    ...riddle
                })
            })

            if (this.student !== null  && this.student.answeredRiddles.length !== 0) {
                this.student.answeredRiddles.forEach(answeredRiddle => {
                    newRiddleArr.forEach((riddle) => {
                        if (riddle.id.toString() === answeredRiddle.toString()) {
                            riddle.isAnswered = true
                        }
                    })
                })
            }

            console.log(newRiddleArr);
            return newRiddleArr
        }
    }
};

</script>

<style scoped>
#riddlePhoto {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    /* background-color: #8bbaff; */
}
</style>
<template>
    <v-container>
        <!-- <v-dialog v-model="loading.isLoading" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text class="pt-2">
                    {{ loading.message }}
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog> -->
        <div id="loading-container">
            <v-progress-circular :size="100" :width="5" color="#0D47A1" indeterminate>
                <v-img :src="require('../assets/eclipse.png')" max-width="50" contain></v-img>
            </v-progress-circular>
            <p class="text-subtitle-1 mt-4">Please wait...</p>
        </div>
    </v-container>
</template>
  
<script>
export default {
    name: 'Loading',

    props: [
        'loading',
    ],

    data: () => ({
        //
    }),
}
</script>

<style scoped>
#loading-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 75vh;
}
</style>
  
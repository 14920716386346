<template>
  <div>
    <!-- Creates a student card -->
    <v-row dense>
      <v-col cols="12" sm="4" v-for="(student, index) in students" :key="index">
        <v-card outlined flat class="d-flex align-center" @click="redirect(student)">
          <v-img id="studentPhoto" src="../../assets/eclipse.png" contain max-width="75" max-height="75"
            class="my-2 ml-2 mr-2"></v-img>

          <v-card-text class="py-0">
            <p class="text-subtitle-1 font-weight-bold mb-0 mt-2">{{ student.lastName.toUpperCase() }}</p>
            <p class="text-body">{{ student.firstName }}</p>
            <!-- <p class="text-caption mb-2">Absences: {{ student.absences }}</p> -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Student',

  props: [
    'students'
  ],

  data: () => ({

  }),

  methods: {
    redirect: function (student) {
      this.$router.push({ name: 'Student Details', params: student })
    }
  }
};

</script>

<style scoped>
/* *{
  border: 1px solid red;
} */
#studentPhoto {
  border: 1px solid #0D47A1;
  border-radius: 5px;
}
</style>
import Riddles from '../../views/Riddle/Riddles'

export default [
    {
        path: '/riddles',
        name: 'Riddles',
        component: Riddles,
        meta: {
            isAuthenticated: true,
            permissions: ['DEVELOPER', 'STUDENT']
        },
        props: true
    },
]
<template>
    <v-container>
        <v-row class="mt-4">
            <v-col cols="12" sm="4" md="3" v-for="(achievement, index) in achievementImages" :key="index" class="d-flex flex-column align-center">
                <v-img :src="require('../assets/badges/' + achievement.month + '.png')" max-height="250" max-width="250"></v-img>
                <p class="text-subtitle-1 font-weight-bold mb-0 mt-2">{{ achievement.badge.toUpperCase() }} BADGE</p>
                <p class="text-caption">{{ achievement.month.toUpperCase() }}</p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'Badges',

    data: () => ({
        achievementImages: [
            { month: 'january', badge: 'aquarius' },
            { month: 'february', badge: 'pisces' },
            { month: 'march', badge: 'aries' },
            { month: 'april', badge: 'taurus' },
            { month: 'may', badge: 'gemini' },
            { month: 'june', badge: 'cancer' },
            { month: 'july', badge: 'leo' },
            { month: 'august', badge: 'virgo' },
            { month: 'september', badge: 'libra' },
            { month: 'october', badge: 'scorpio' },
            { month: 'november', badge: 'sagittarius' },
            { month: 'december', badge: 'capricorn' },
        ]
    })
};

</script>

<style scoped></style>
<template>
  <div>
    <chart />
    <hello-world />
  </div>
</template>

<script>
import HelloWorld from '../components/HelloWorld'
import Chart from '../components/Chart'

export default {
  name: 'Home',

  components: {
    HelloWorld,
    Chart
  },
}
</script>

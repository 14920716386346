<template>
  <v-container>
    <Loading v-if="!isPageLoaded" />

    <div v-if="isPageLoaded">
      <p class="mt-4 text-h6 font-weight-bold" id="attendance-date-title">Students' Attendance</p>
      <v-row>
        <v-col cols="12" md="9" class="order-last order-md-first">
          <v-card flat>
            <Table :headers="tableHeaders" :items="tableItems" @edit="editRow($event)" />
          </v-card>
        </v-col>

        <v-col cols="12" md="3" class="order-first order-md-last">
          <!-- Allows user to select a date to view a specific attendance -->
          <v-select outlined dense :items="attendance.dateOptions" v-model="attendance.selectedAttendance"></v-select>

          <!-- The attendance chart -->
          <v-card outlined flat class="pa-4">
            <p class="text-subtitle-2 font-weight-bold"><span class="text-h5">{{ students.length }} </span>Students
              in
              total</p>

            <div style="height: 200px;">
              <Chart :chartData="chartData" />
            </div>
          </v-card>
        </v-col>
      </v-row>

      <!-- Dialog for editing time in attendance -->
      <v-dialog v-model="dialogs.edit" max-width="500" max-height="500" persistent>
        <v-card class="pa-4">
          <v-card-title><span class="mx-auto" style="color: #0D47A1;">CHANGE TIME</span></v-card-title>

          <v-select outlined label="TYPE" v-model="editAttendance.type" :items="typeOptions"></v-select>

          <v-dialog width="290px" v-model="dialogs.timePicker">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field outlined label="TIME" v-model="editAttendance.time" v-on="on" v-bind="attrs"
                readonly></v-text-field>
            </template>
            <v-time-picker format="ampm" v-model="editAttendance.time"></v-time-picker>
          </v-dialog>

          <v-btn block class="mt-2 white--text" color="#0D47A1" @click="saveNewTime()">
            <v-icon class="mr-2">mdi-send</v-icon>
            NOTIFY GUARDIAN
          </v-btn>

          <v-btn block text class="mt-2" color="red" @click="dialogs.edit = false">
            <v-icon class="mr-2">mdi-close</v-icon>
            CLOSE
          </v-btn>
        </v-card>
      </v-dialog>
    </div>

    <!-- <Loading :loading="loading" /> -->
  </v-container>
</template>
  
<script>
import Table from '../../components/Table.vue'
import Chart from '../../components/Chart.vue'
import Loading from '../../components/Loading.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Attendance',

  components: {
    Table,
    Chart,
    Loading
  },

  data: () => ({
    isPageLoaded: false,
    attendance: {
      selectedAttendance: 'All Attendances',
      dateOptions: ['All Attendances']
    },

    singleRowData: null,
    typeOptions: ['Morning', 'Afternoon'],

    editAttendance: {
      type: null,
      time: null
    },

    dialogs: {
      edit: false
    },

    tableHeaders: [
      {
        text: 'NAME',
        value: 'name',
        align: 'start'
      },
      {
        text: 'DATE',
        value: 'date'
      },
      // {
      //   text: 'DAY',
      //   value: 'day'
      // },
      {
        text: 'MORNING',
        value: 'morning'
      },
      {
        text: 'AFTERNOON',
        value: 'afternoon'
      },
      {
        text: 'ACTIONS',
        value: 'actions'
      }
    ],

    morningAttendanceCount: 0,
    afternoonAttendanceCount: 0,

    chartData: {
      data: {
        labels: ['MORNING', 'AFTERNOON'],
        datasets: [{
          label: 'ATTENDANCE',
          data: [],
          backgroundColor: '#0D47A1'
        }]
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'x',
        plugins: {
          legend: {
            display: false,
            position: 'bottom',
            align: 'start'
          }
        }
      },

      description: 'Percentage of morning and afternoon attendances.'
    }
  }),

  computed: {
    ...mapState('students', {
      students: state => state.allStudents,
    }),

    ...mapState('attendance', {
      attendances: state => state.allAttendances,
      attendanceDates: state => state.attendanceDates,
      // loading: state => state.loading
    }),

    tableItems() {
      //chartData.data.datasets.data
      let singleDateAttendance = []
      this.morningAttendanceCount = 0
      this.afternoonAttendanceCount = 0

      //Options for selecting attendance dates
      if (this.attendance.dateOptions.length === 1 && this.attendanceDates.length !== 0) {
        this.attendanceDates.forEach(date => {
          this.attendance.dateOptions.push(date)
        })
      }

      //Sets the chart data when all attendances are displayed
      if (this.attendance.selectedAttendance === 'All Attendances') {
        this.attendances.forEach(attendance => {
          if (attendance.morning !== '--') {
            this.morningAttendanceCount = this.morningAttendanceCount + 1
          }
          if (attendance.afternoon !== '--') {
            this.afternoonAttendanceCount = this.afternoonAttendanceCount + 1
          }
        })
        this.chartData.data.datasets[0].data = [this.morningAttendanceCount, this.afternoonAttendanceCount]
        console.log(this.chartData.data.datasets[0].data);
      }

      //Filtering attendances based on selected date
      if (this.attendance.selectedAttendance === 'All Attendances') {
        return this.attendances
      } else {

        this.attendances.forEach(attendance => {
          if (this.attendance.selectedAttendance === attendance.date) {
            singleDateAttendance.push(attendance)
          }
        })
        return singleDateAttendance
      }
    }
  },

  methods: {
    ...mapActions('students', ['getAllStudents']),
    ...mapActions('attendance', ['getAllAttendance', 'getAttendanceDates', 'messageGuardian', 'updateSingleAttendance']),

    editRow: function (rowData) {
      this.singleRowData = rowData
      this.dialogs.edit = true
    },

    saveNewTime: function () {
      this.notifyGuardian()
      this.tableItems.forEach(student => {
        if (student.apiKey === this.singleRowData.apiKey) {
          if (this.editAttendance.type === 'Morning') {
            student.morning = this.editAttendance.time + ' AM'
            this.singleRowData.morning = student.morning
          } else {
            let time = this.editAttendance.time.split(':')
            time[0] > 12 ? time[0] = time[0] - 12 : time[0] //Formats time to 12 hour format
            student.afternoon = time.join(':') + ' PM'
            this.singleRowData.afternoon = student.afternoon
          }
        }
      })

      this.updateSingleAttendance(this.singleRowData)

      this.dialogs.edit = false
    },

    notifyGuardian: function () {
      //Morning
      //Magandang%20Umaga%21%0A%0AAng%20iyong%20anak%20na%20si%20Prince%20Charles%20Clemente%20ay%20nasa%20eskwelahan%20ngayong%20July%206%20sa%20oras%20na%207%3A30%20ng%20umaga.      
      //Magandang%20Umaga%21%0A%0ANais%20naming%20ipaalam%20na%20ang%20inyong%20anak%20ay%20wala%20sa%20klase%20ngayong%20July%206%20sa%20oras%20na%207%3A30%20ng%20umaga.%20Makatatanggap%20kayo%20ng%20bagong%20mensahe%20kung%20ang%20inyong%20anak%20ay%20nahuli%20lamang%20sa%20klase.

      //Afternoon
      //Magandang%20Hapon%21%0A%0AAng%20klase%20ng%20iyong%20anak%20na%20si%20Prince%20Charles%20Clemente%20ngayong%20July%206%20ay%20natapos%20sa%20oras%20na%204%3A00%20ng%20hapon.%20Ang%20mga%20klase%20para%20sa%20araw%20na%20ito%20ay%20tapos%20na%20at%20ang%20mga%20estudyante%20ay%20maaari%20ng%20umuwi.
      //Magandang%20Hapon%21%0A%0ANais%20naming%20ipaalam%20na%20ang%20inyong%20anak%20ay%20wala%20sa%20klase%20ngayong%20July%206%20sa%20oras%20na%204%3A30%20ng%20hapon.%20Ang%20mga%20klase%20para%20sa%20araw%20na%20ito%20ay%20tapos%20na%20at%20ang%20mga%20estudyante%20ay%20maaari%20ng%20umuwi.

      let message = 'Test Message'
      const dateArray = this.singleRowData.date.split('-')
      let arrivedTime = this.editAttendance.time.split(':').join('%3A')
      const date = new Date(dateArray[0], dateArray[1] - 1, dateArray[2])
      const month = date.toLocaleString('default', { month: 'long' })
      const studentName = this.singleRowData.name.split(' ').join('%20') //Encode the student's name
      // const studentName = studentNameArray.join('%20')

      if (this.editAttendance.type === 'Morning') {
        message = `Magandang%20Umaga%21%0A%0AAng%20iyong%20anak%20na%20si%20${studentName}%20ay%20nasa%20eskwelahan%20ngayong%20${month}%20${dateArray[2]}%20sa%20oras%20na%20${arrivedTime}%20ng%20umaga.`
      } else {
        message = `Magandang%20Hapon%21%0A%0AAng%20klase%20ng%20iyong%20anak%20na%20si%20${studentName}%20ngayong%20${month}%20${dateArray[2]}%20ay%20natapos%20sa%20oras%20na%20${arrivedTime}%20ng%20hapon.%20Ang%20mga%20klase%20para%20sa%20araw%20na%20ito%20ay%20tapos%20na%20at%20ang%20mga%20estudyante%20ay%20maaari%20ng%20umuwi.`
      }

      this.messageGuardian({
        apiKey: this.singleRowData.apiKey,
        message: message
      })
    },
  },

  mounted: async function() {
    await this.getAllStudents()
    await this.getAllAttendance()
    await this.getAttendanceDates()

    this.isPageLoaded = true
  },

  watch: {
    attendance: {
      handler(newValue, oldValue) {
        // this.morningAttendanceCount = Math.floor(Math.random()*100)
        // this.afternoonAttendanceCount = Math.floor(Math.random()*100)
        this.morningAttendanceCount = 0
        this.afternoonAttendanceCount = 0
        console.log(this.chartData.data.datasets[0].data);


        console.log(newValue.selectedAttendance);
        if (newValue.selectedAttendance !== 'All Attendances') {
          this.tableItems.forEach(item => {
            if (item.morning !== '--') {
              this.morningAttendanceCount = this.morningAttendanceCount + 1
            }
            if (item.afternoon !== '--') {
              this.afternoonAttendanceCount = this.afternoonAttendanceCount + 1
            }
          })
          this.chartData.data.datasets[0].data = [this.morningAttendanceCount, this.afternoonAttendanceCount]
          console.log(this.chartData.data.datasets[0].data);
        }
      },
      deep: true
    }
  }
};

</script>
  
<style scoped>
#attendance-date-title {
  color: #0D47A1;
}

/* *{
  border: 1px solid red;
} */
</style>
<template>
    <!-- TO DO: Make an option to choose between horizontal or vertical bar chart. -->

    <div class="wrapper">
        <p class="font-weight-light text-caption mb-2" v-if="chartData.description">{{ chartData.description }}</p>
        <Bar :data="chartData.data" :options="chartData.options" class="mb-4" ref="chart" :key="chartKey" />
        <!-- {{ chartData.data.datasets[0].data }}
        {{ chartData.data.datasets[1].data }} -->
        <!-- {{ chartOptions }} -->
    </div>
</template>
  
<script>
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js'
import { Bar } from 'vue-chartjs'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'Chart',

    props: [
        'chartData',
    ],

    components: {
        Bar
    },
    data() {
        return {
            chartKey: 0,
        }
    },
    watch: {
        chartData: {
            handler(newValue, oldValue) {
                this.chartKey = this.chartKey + 1
            },
            deep: true
        }
    }
}
</script>

<style scoped>
.wrapper {
    height: 90%;
}
</style>

<template>
  <v-container>
    <Loading v-if="!isPageLoaded" />

    <div v-if="isPageLoaded">
      <p class="mt-4 text-h6 font-weight-bold" id="students-list-title">List of Students</p>

    <!-- A separate search bar for searching a student by name
    <v-text-field v-model="search" single-line outlined dense>
      <template v-slot:append>
        <v-btn color="#0D47A1" class="white--text mb-2">
          <v-icon>mdi-magnify</v-icon>
          Search
        </v-btn>
      </template>
    </v-text-field> -->

    <v-row>
      <!-- Displays all students -->
      <v-col cols="12" md="9" class="order-last order-md-first">
        <Student :students="students" />
      </v-col>

      <!-- Displays a chart of male to female students ratio -->
      <v-col cols="12" md="3" class="order-first order-md-last">
        <v-card outlined flat class="pa-4">
          <p class="text-subtitle-2 font-weight-bold"><span class="text-h5">{{ students.length }} </span>Students in
            total</p>

          <div style="height: 200px;">
            <Chart :chartData="chartData" />
          </div>
        </v-card>

        <!-- Opens a dialog/form for adding a new student -->
        <v-dialog v-model="dialogs.add" max-width="500" max-height="500" persistent>
          <template v-slot:activator="{ on, attrs }">
            <v-btn block class="mt-2 white--text" color="#0D47A1" v-on="on">
              <v-icon class="mr-2">mdi-plus-box</v-icon>
              ADD NEW STUDENT
            </v-btn>
          </template>

          <v-card class="pa-4">
            <v-card-title><span class="mx-auto" style="color: #0D47A1;">ADD NEW STUDENT</span></v-card-title>

            <v-text-field outlined label="FIRST NAME" v-model="newStudentForm.firstName"></v-text-field>
            <v-text-field outlined label="MIDDLE NAME" v-model="newStudentForm.middleName"></v-text-field>
            <v-text-field outlined label="LAST NAME" v-model="newStudentForm.lastName"></v-text-field>
            <v-select outlined :items="genderOptions" label="GENDER" v-model="newStudentForm.gender"></v-select>
            <v-text-field outlined label="AGE" v-model="newStudentForm.age"></v-text-field>
            <v-text-field outlined label="GUARDIAN" v-model="newStudentForm.guardian"></v-text-field>
            <v-text-field outlined label="GUARDIAN'S CONTACT NO." v-model="newStudentForm.guardianContact"></v-text-field>
            <v-text-field outlined label="API KEY" v-model="newStudentForm.apiKey"></v-text-field>

            <v-btn block class="mt-2 white--text" color="#0D47A1" @click="addStudent()">
              <v-icon class="mr-2">mdi-plus-box</v-icon>
              ADD STUDENT
            </v-btn>
            <v-btn block outlined class="mt-2" color="red" @click="dialogs.add = false">
              <v-icon class="mr-2">mdi-close</v-icon>
              CLOSE
            </v-btn>
          </v-card>
        </v-dialog>

        <v-btn block class="mt-2 white--text" color="#2196F3" @click="generateQrCodes()" :loading="loading">
          <v-icon class="mr-2">mdi-download</v-icon>
          GENERATE QR CODES
        </v-btn>

        <!-- Opens a dialog with a warning when trying to delete the class -->
        <v-dialog v-model="dialogs.delete" max-width="500" max-height="500" persistent>
          <template v-slot:activator="{ on, attrs }">
            <v-btn block outlined class="mt-2" color="red" v-on="on">
              <v-icon class="mr-2">mdi-delete</v-icon>
              DELETE CLASS
            </v-btn>
          </template>

          <v-card class="pa-4">
            <v-card-title><span class="mx-auto" style="color: red;">WARNING</span></v-card-title>
            <v-card-text class="text-center">
              Are you sure you want to delete this class? All students and their information will be removed.
            </v-card-text>

            <v-btn block class="mt-2 white--text" color="red" @click="deleteClass()">
              <v-icon class="mr-2">mdi-delete</v-icon>
              DELETE ANYWAY
            </v-btn>
            <v-btn block outlined class="mt-2" color="red" @click="dialogs.delete = false">
              <v-icon class="mr-2">mdi-close</v-icon>
              CLOSE
            </v-btn>
          </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbars.delete.isSnackbar" :timeout="snackbars.delete.timeout" top
          :color="snackbars.delete.isSuccess ? 'success' : 'red accent-2'">
          {{ snackbars.delete.message }}
        </v-snackbar>
        <v-snackbar v-model="snackbars.add.isSnackbar" :timeout="snackbars.add.timeout" top
          :color="snackbars.add.isSuccess ? 'success' : 'red accent-2'">
          {{ snackbars.add.message }}
        </v-snackbar>
      </v-col>
    </v-row>
    </div>
  </v-container>
</template>

<script>
import Student from './Student.vue'
import Chart from '../../components/Chart.vue'
import { mapActions, mapState } from 'vuex'
import Loading from '../../components/Loading.vue'

export default {
  name: 'Students',

  components: {
    Student,
    Chart,
    Loading
  },

  data: () => ({
    isPageLoaded: false,
    loading: false,

    genderOptions: ['Male', 'Female'],

    dialogs: {
      add: false,
      delete: false,
    },

    // snackbars: {
    //   add: {
    //     isSnackbar: false,
    //     text: 'Student added successfully.',
    //     timeout: 3000
    //   },
    //   delete: {
    //     isSnackbar: false,
    //     text: 'Class deleted successfully.',
    //     timeout: 3000
    //   }
    // },

    search: null,
    // buttonColor: '#00CCB9',

    newStudentForm: {
      reference: null,
      firstName: null,
      middleName: null,
      lastName: null,
      gender: null,
      guardian: null,
      guardianContact: null,
      apiKey: null,
      age: null,
      points: 0,
      rewards: [],
      badges: [],
      awardNotice: [],
      answeredRiddles: []
    },

    chartData: {
      data: {
        labels: ['MALE', 'FEMALE'],
        datasets: [{
          label: 'GENDER',
          data: [15, 12],
          backgroundColor: '#2196F3'
        }]
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'x',
        plugins: {
          legend: {
            display: false,
            position: 'bottom',
            align: 'start'
          }
        }
      },

      description: 'Male to female students ratio.'
    },

    studentsDetails: [
      {
        lastName: 'Miranda',
        firstName: 'Charles',
        absences: 3
      },
      {
        lastName: 'Clemente',
        firstName: 'Prince',
        absences: 3
      },
      // {
      //   lastName: 'Lapid',
      //   firstName: 'Cathlyn',
      //   absences: 3
      // },
      // {
      //   lastName: 'De Guzman',
      //   firstName: 'Mae',
      //   absences: 3
      // },
      {
        lastName: 'Clemente',
        firstName: 'Prince Charles',
        absences: 3
      },
      // {
      //   lastName: 'Lapid',
      //   firstName: 'Cathlyn Mae',
      //   absences: 3
      // }
    ]
  }),

  computed: {
    ...mapState('students', {
      snackbars: state => state.snackbars,
      students: state => state.allStudents
    })
  },

  methods: {
    ...mapActions('students', ['addNewStudent', 'getAllStudents']),
    ...mapActions('authentication', ['registerUser']),

    addStudent: function () {
      this.newStudentForm.reference = Math.floor(Math.random()*1000000) //Sets a reference number
      console.log(this.newStudentForm);
      this.addNewStudent(this.newStudentForm)
      this.dialogs.add = false
      this.getAllStudents()
      // this.registerUser({
      //   email: this.newStudentForm.reference.toString() + '@gmail.com',
      //   password: this.newStudentForm.reference
      // })

      this.newStudentForm = {
        firstName: null,
        middleName: null,
        lastName: null,
        gender: null,
        guardian: null,
        guardianContact: null,
        apiKey: null,
        age: null,
        points: 0,
        rewards: [],
        badges: [],
        awardNotice: [],
        answeredRiddles: []
      }
    },

    deleteClass: function () {
      // alert('class has been deleted')
      this.dialogs.delete = false
      this.snackbars.delete.isSnackbar = true
    },

    generateQrCodes: function () {
      this.loading = true
      let url = null
      let filename = 'chronocheck.png'
      let color = '0D47A1'
      let bgColor = 'FFFFFF'

      this.students.forEach((student) => {

        url = `https://api.qrserver.com/v1/create-qr-code/?data=${student.apiKey}&size=500x500&color=${color}&bgcolor=${bgColor}`

        fetch(url)
          .then(res => res.blob())
          .then(data => {
            filename = `${student.lastName}_${student.firstName}.png`

            var anchor = document.createElement("a") //creates an anchor tag/element
            anchor.href = window.URL.createObjectURL(data)
            anchor.download = filename; //force download of qr code
            anchor.click()

            window.URL.revokeObjectURL(url) //removes generated link for qr code download
          })
          .catch(error => {
            console.log(error)
          })
      })

      this.loading = false

    }
  },

  mounted: async function() {
    await this.getAllStudents()
    this.isPageLoaded = true
  }
};

</script>

<style scoped>
#students-list-title {
  color: #0D47A1;
}
</style>
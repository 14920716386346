<template>
  <v-container>
    <Loading v-if="!isPageLoaded" />

    <div v-if="isPageLoaded">
      <p class="mt-4 text-h6 font-weight-bold" id="attendance-chart-title">Attendance Chart</p>
      <v-card outlined flat height="300" class="px-4 py-6 mb-10">
        <Chart :chartData="chartData" />
      </v-card>

      <p class="text-h6 font-weight-bold" id="students-list-title">List of Students</p>
      <v-card flat>
        <Table :headers="tableHeaders" :items="tableItems" />
      </v-card>
    </div>
  </v-container>
</template>

<script>
import Chart from '../../components/Chart.vue'
import Table from '../../components/Table.vue'
import { mapActions, mapState } from 'vuex'
import Loading from '../../components/Loading.vue'

export default {
  name: 'Dashboard',

  components: {
    Chart,
    Table,
    Loading
  },

  data: () => ({
    isPageLoaded: false,
    chartLabels: [],
    datasetOne: [],
    datasetTwo: [],
    morningAttendanceCount: 0,
    afternoonAttendanceCount: 0,

    tableHeaders: [
      {
        text: 'NAME',
        value: 'name',
        align: 'start'
      },
      {
        text: 'AGE',
        value: 'age'
      },
      {
        text: 'GENDER',
        value: 'gender'
      },
      {
        text: 'GUARDIAN',
        value: 'guardian'
      }
    ]
  }),

  computed: {
    ...mapState('students', {
      students: state => state.allStudents
    }),

    ...mapState('attendance', {
      attendances: state => state.allAttendances,
      attendanceDates: state => state.attendanceDates,
      loading: state => state.loading
    }),

    chartData() {
      //Sets the chart's date labels and datasets
      this.chartLabels = [] // Empties the labels datasets whenever the chart's data changes to store new data
      this.datasetOne = []
      this.datasetTwo = []

      this.attendanceDates.forEach(attendanceDate => {

        let dateArray = attendanceDate.split('-')
        let date = new Date(dateArray[0], dateArray[1] - 1, dateArray[2])
        let month = date.toLocaleString('default', { month: 'long' }) //Formats the month as string

        this.chartLabels.push(`${month} ${dateArray[2]}`)

        this.attendances.forEach(attendance => {
          if (attendance.date === attendanceDate) {
            if (attendance.morning !== '--') {
              this.morningAttendanceCount = this.morningAttendanceCount + 1
            }
            if (attendance.afternoon !== '--') {
              this.afternoonAttendanceCount = this.afternoonAttendanceCount + 1
            }
          }
        })

        this.datasetOne.push(this.morningAttendanceCount)
        this.datasetTwo.push(this.afternoonAttendanceCount)
        this.morningAttendanceCount = 0
        this.afternoonAttendanceCount = 0
      })

      return {
        data: {
          labels: this.chartLabels, //'JULY 1', 'JULY 2', 'JULY 3', 'JULY 4', 'JULY 5', 'JULY 6', 'JULY 7'
          datasets: [{
            label: 'MORNING',
            data: this.datasetOne, //40, 20, 12, 34, 52, 23, 17
            backgroundColor: '#0D47A1'
          },
          {
            label: 'AFTERNOON',
            data: this.datasetTwo, //12, 10, 42, 13, 45, 34, 14
            backgroundColor: '#2196F3'
          }]
        },

        options: {
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: 'x',
          plugins: {
            legend: {
              position: 'bottom',
              align: 'start'
            }
          }
        },

        description: 'Students who attended morning and afternoon classes.'
      }
    },

    tableItems() {
      //Get necessary information of each students.
      let studentsDetails = []
      this.students.forEach(student => {
        studentsDetails.push({
          name: `${student.firstName.toUpperCase()} ${student.lastName.toUpperCase()}`,
          age: student.age,
          gender: student.gender.toUpperCase(),
          guardian: student.guardian.toUpperCase()
        })
      })

      return studentsDetails
    },

    // analyticsData() {

    // }
  },

  methods: {
    ...mapActions('students', ['getAllStudents']),
    ...mapActions('attendance', ['getAllAttendance', 'getAttendanceDates']),
  },

  mounted: async function() {
    await this.getAllStudents()
    await this.getAllAttendance()
    await this.getAttendanceDates()

    this.isPageLoaded = true
  },

  watch: {
    attendance: {
      handler(newValue, oldValue) {
        console.log('Watch called');
      },
      deep: true
    }
  }
};

</script>

<style scoped>
#attendance-chart-title,
#students-list-title {
  color: #0D47A1;
}
</style>
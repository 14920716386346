import Students from '../../views/Students/Students'
import Profile from '../../views/Students/Profile'
import StudentDetails from '../../views/Students/StudentDetails'

export default [
    {
        path: '/students',
        name: 'Students',
        component: Students,
        meta: {
            isAuthenticated: true,
            permissions: ['DEVELOPER', 'INSTRUCTOR']
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {
            isAuthenticated: true,
            permissions: ['STUDENT']
        }
    },
    {
        path: '/student-details/:reference',
        name: 'Student Details',
        component: StudentDetails,
        meta: {
            isAuthenticated: true,
            permissions: ['DEVELOPER', 'INSTRUCTOR']
        },
        props: true
    }
]
<template>
    <v-container>
        <Loading v-if="!isPageLoaded" />

        <div v-if="isPageLoaded">
            <p class="mt-4 text-h6 font-weight-bold" id="riddles-title">Luck and Riddles</p>
            <v-row>
                <v-col cols="12" md="9" class="order-last order-md-first">
                    <Riddle :riddles="allRiddles" :student="student" />
                </v-col>
                <v-col cols="12" md="3" class="order-first order-md-last">
                    <v-card v-if="ongoingRiddle !== null" flat outlined class="pa-4">
                        <v-img style="background-color: transparent;"
                            :src="require('../../assets/cards/' + ongoingRiddle.id.toString() + '.png')" max-width="100"
                            contain></v-img>
                        <v-card-subtitle class="px-0 font-weight-bold">{{ ongoingRiddle.cardName.toUpperCase()
                        }}</v-card-subtitle>

                        <v-card-text class="px-0">{{ ongoingRiddle.question }}</v-card-text>
                        <v-card-text class="text-caption px-0">NOTE: If the answer is a number, type in a number. If
                            it's a word, use capital
                            letters.</v-card-text>
                        <v-text-field outlined v-model="answer.answer" label="Type your answer here"
                            class="mb-0 pb-0"></v-text-field>
                        <v-btn block class="mt-0 pt-0 white--text" color="#0D47A1" @click="checkAnswer()"><v-icon
                                class="mr-2">mdi-send</v-icon>
                            SUBMIT</v-btn>
                    </v-card>

                    <v-card v-else flat class="pa-2" id="guide-poem">
                        <v-card-text class="text-center text-caption pa-4" id="riddle-intro">
                            <v-img src="../../assets/owl_black.png" max-width="50" contain class="mx-auto"></v-img>
                            <v-card-subtitle class="py-0 text-center text-overline font-weight-bold">Before you
                                begin</v-card-subtitle>
                            <v-divider color="white" class="mb-4"></v-divider>
                            In twilight's realm where riddles roam, <br>
                            A price of fifty coins to claim, <br>
                            Unveiling secrets veiled in tome, <br>
                            Unlock the mysteries to name. <br><br>

                            Once opened wide, a riddle springs, <br>
                            A challenge laid upon thy soul,<br>
                            To seek the truth, where knowledge rings,<br>
                            And reason's flame shall make thee whole.<br><br>

                            If wit prevails and answers gleam,<br>
                            A prize of points, a worthy gain,<br>
                            Like stars that dance in skies agleam,<br>
                            Thy fortune blessed with doubled rain.<br><br>

                            Yet fortune's wheel, it spins and turns, <br>
                            In luck's embrace or sorrow's plight, <br>
                            Should fate decree the lesson learned, <br>
                            Less points bestowed, a dimmer light.<br><br>

                            So venture forth, ye seekers keen, <br>
                            Embrace the riddles' beguiling art, <br>
                            In quest for knowledge, sights unseen,<br>
                            A journey stirs within thy heart.
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <!-- Dialog for showing if answer is right or wrong -->
            <v-dialog v-if="answer.answer !== ''" v-model="dialogs.answer" max-width="500" max-height="500" persistent>
                <v-card class="pa-4">
                    <v-card-title><span class="mx-auto" style="color: #0D47A1;">{{ answer.isCorrect ? 'CONGRATULATIONS' :
                        "DON'T GIVE UP" }}</span></v-card-title>
                    <v-card-text class="text-center">{{ answer.isCorrect ? 'You got it right!' : "Just keep on
                    trying."}}</v-card-text>

                    <div v-if="answer.isCorrect">
                        <v-img style="background-color: transparent;"
                            :src="require('../../assets/cards/' + ongoingRiddle.id.toString() + '.png')" max-width="100"
                            contain class="mx-auto mb-4"></v-img>

                        <v-card-subtitle class="text-center">You earned: {{ answer.reward }} points</v-card-subtitle>
                    </div>

                    <v-btn block outlined class="mt-2" color="red" @click="closeDialog()">
                        <v-icon class="mr-2">mdi-close</v-icon>
                        CLOSE
                    </v-btn>
                </v-card>
            </v-dialog>
        </div>
    </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Riddle from '../Riddle/Riddle.vue'
import Loading from '../../components/Loading.vue'

export default {
    name: 'Riddles',

    components: {
        Riddle,
        Loading
    },

    data: () => ({
        isPageLoaded: false,
        ongoingRiddle: null,
        currentUser: null,
        answer: {
            answer: '',
            isCorrect: false,
            reward: 0
        },
        dialogs: {
            answer: false
        }
    }),

    methods: {
        ...mapActions('gamify', ['getAllRiddles', 'rewardRiddlePoints', 'resetRiddle']),
        ...mapActions('students', ['getSingleStudent']),

        checkAnswer: function () {
            console.log(this.answer.answer);
            if (this.answer.answer === this.ongoingRiddle.answer.toUpperCase()) {
                this.answer.isCorrect = true
                this.answer.reward = Math.floor(Math.random() * 101)
                this.rewardRiddlePoints({
                    reference: this.student.reference,
                    pointsEarned: this.answer.reward,
                    riddleId: this.ongoingRiddle.id
                })
                this.resetRiddle(this.currentUser.reference)

            } else {
                this.answer.isCorrect = false
            }
            this.dialogs.answer = true
        },

        closeDialog: function () {
            this.dialogs.answer = false
            this.answer.answer = ''
            this.answer.isCorrect ? this.$router.push({ name: 'Profile' }) : null
        }
    },

    computed: {
        ...mapState('gamify', {
            allRiddles: state => state.allRiddles,
        }),

        ...mapState('students', {
            student: state => state.singleStudent
        }),
    },

    watch: {
        student: {
            handler(newValue, oldValue) {
                console.log(newValue)
                this.allRiddles.forEach(riddle => {
                    console.log(riddle);

                    if (riddle.id.toString() === this.student.riddle.toString()) {
                        console.log(riddle);
                        this.ongoingRiddle = riddle
                    }
                })
            },
            deep: true
        }
    },

    mounted: async function () {
        await this.getAllRiddles()
        this.currentUser = JSON.parse(localStorage.getItem('user'))

        if (this.currentUser.role === 'student') {
            this.getSingleStudent(this.currentUser.reference)
        }

        this.isPageLoaded = true
    }
};

</script>

<style scoped>
#riddles-title {
    color: #0D47A1;
}

#riddle-intro {
    border: 1px solid rgb(153, 153, 153);
    border-radius: 5px;
}

#guide-poem {
  background-color: rgb(242, 241, 255);
}
</style>
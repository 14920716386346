<template>
    <v-container>
        <Loading v-if="!isPageLoaded" />

        <div v-if="isPageLoaded">
            <p class="mt-4 text-h6 font-weight-bold" id="reward-award-title">Rewards and Award</p>
            <v-row>
                <v-col v-if="currentUser.role !== 'student'" cols="12" md="3" class="order-2 order-md-1">
                    <!-- Lists available rewards -->
                    <v-divider class="mb-2" color="#0D47A1"></v-divider>
                    <p class="text-subtitle-2 d-flex align-start">
                        <v-icon small color="#0D47A1" class="mr-2">mdi-gift</v-icon> List of Available Rewards
                    </p>

                    <v-row dense>
                        <v-col cols="12" v-for="(reward, index) in allRewards" :key="index">
                            <v-card outlined flat class="d-flex py-2">
                                <v-img id="rewardPhoto" :src="require('../../assets/rewards/' + reward.image + '.png')"
                                    contain max-width="50" max-height="50" class="ml-2 mr-2"></v-img>

                                <div style="width: 100%;" class="d-flex flex-column justify-space-between">
                                    <p class="text-subtitle-2 font-weight-bold mb-0">{{ reward.name }}</p>
                                    <v-card-text class="pa-0 d-flex align-center ma-0">
                                        <p class="text-caption ma-0">{{ reward.points }} points</p>
                                        <v-btn x-small outlined rounded class="red--text my-0 mr-2 ml-auto"
                                            @click="selectRewardToDelete(reward)">Delete</v-btn>
                                    </v-card-text>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col v-else cols="12" md="3" class="order-first order-md-last">

                    <!-- Riddle redirect button -->
                    <v-card flat class="mb-6 pa-4 d-flex flex-column align-center" id="riddle-prompt">
                        <v-img src="../../assets/owl_black.png" max-width="50" contain></v-img>
                        <v-card-subtitle class="mb-0 pb-0 text-center font-weight-bold">Test your luck with
                            riddles
                            and earn extra points!</v-card-subtitle>
                        <v-card-text class="text-center text-caption">Or not.</v-card-text>
                        <v-btn outlined block class="gray--text" @click="redirectToRiddles()">OPEN RIDDLES</v-btn>
                    </v-card>

                    <!-- Lists available rewards -->
                    <v-divider class="mb-2" color="#0D47A1"></v-divider>
                    <p class="text-subtitle-2 d-flex align-start">
                        <v-icon small color="#0D47A1" class="mr-2">mdi-gift</v-icon> List of Available Rewards
                    </p>

                    <v-row dense>
                        <v-col cols="12" v-for="(reward, index) in allRewards" :key="index">
                            <v-card outlined flat class="d-flex py-2">
                                <v-img id="rewardPhoto" :src="require('../../assets/rewards/' + reward.image + '.png')"
                                    contain max-width="50" max-height="50" class="ml-2 mr-2"></v-img>

                                <div style="width: 100%;" class="d-flex flex-column justify-space-between">
                                    <p class="text-subtitle-2 font-weight-bold mb-0">{{ reward.name }}</p>
                                    <v-card-text class="pa-0 d-flex align-center ma-0">
                                        <p class="text-caption ma-0">{{ reward.points }}</p>
                                        <v-btn x-small outlined rounded class="green--text my-0 mr-2 ml-auto"
                                            @click="selectRewardToRedeem(reward)">Redeem</v-btn>
                                    </v-card-text>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>

                <!-- Table for monthly best in attendance award -->
                <v-col v-if="currentUser.role !== 'student'" cols="12" md="6" class="order-3 order-md-2">
                    <v-card id="reward-banner" class="mb-6">
                        <v-img src="../../assets/award_bg.png" max-height="100">
                            <v-card-subtitle
                                class="mt-2 mb-0 pb-0 text-center text-caption text-md-subtitle-2 white--text">Remaining
                                qualified students for the monthly</v-card-subtitle>
                            <v-card-title class="mt-0 pt-0"><span class="mx-auto text-subtitle-2 text-md-h5"
                                    style="color: white;">BEST IN ATTENDANCE AWARD - {{ new Date().toLocaleString('default',
                                        {
                                            month: 'long'
                                        }).toUpperCase() }}</span></v-card-title>
                        </v-img>
                    </v-card>
                    <v-card flat>
                        <Table :headers="tableHeaders" :items="tableItems" />
                    </v-card>
                </v-col>
                <v-col v-else cols="12" md="9" class="order-last order-md-first">
                    <v-card id="reward-banner" class="mb-6">
                        <v-img src="../../assets/award_bg.png" max-height="100">
                            <v-card-subtitle
                                class="mt-2 mb-0 pb-0 text-center text-caption text-md-subtitle-2 white--text">Remaining
                                qualified students for the monthly</v-card-subtitle>
                            <v-card-title class="mt-0 pt-0"><span class="mx-auto text-subtitle-2 text-md-h5"
                                    style="color: white;">BEST IN ATTENDANCE AWARD - {{ new Date().toLocaleString('default',
                                        {
                                            month: 'long'
                                        }).toUpperCase() }}</span></v-card-title>
                        </v-img>
                    </v-card>
                    <v-card flat>
                        <Table :headers="tableHeaders" :items="tableItems" />
                    </v-card>
                </v-col>

                <v-col v-if="currentUser.role !== 'student'" cols="12" md="3" class="order-1 order-md-3">
                    <Scanner :buttons="scannerButtons" @addNewReward="dialogs.reward = true" />
                </v-col>
            </v-row>

            <!-- Dialog for adding new reward -->
            <v-dialog v-model="dialogs.reward" max-width="500" max-height="500" persistent>
                <v-card class="pa-4">
                    <v-card-title><span class="mx-auto" style="color: #0D47A1;">ADD NEW REWARD</span></v-card-title>

                    <v-text-field outlined label="REWARD NAME" v-model="newRewardForm.name"></v-text-field>
                    <v-text-field outlined label="REQUIRED POINTS" v-model="newRewardForm.points"></v-text-field>

                    <p class="text-subtitle-2">CHOOSE AN IMAGE</p>
                    <v-row dense class="mb-4">
                        <v-col cols="2" v-for="(image, index) in imageChoices" :key="index">
                            <v-img class="imageChoices" :src="require('../../assets/rewards/' + image.fileName)"
                                :style="`background-color: ${image.color}`" @click="selectImage(image.value)"></v-img>
                        </v-col>
                    </v-row>

                    <v-btn block class="mt-2 white--text" color="#0D47A1" @click="addReward()">
                        <v-icon class="mr-2">mdi-plus-box</v-icon>
                        ADD REWARD
                    </v-btn>
                    <v-btn block outlined class="mt-2" color="red" @click="dialogs.reward = false">
                        <v-icon class="mr-2">mdi-close</v-icon>
                        CLOSE
                    </v-btn>
                </v-card>
            </v-dialog>

            <!-- Dialog for redeeming and deleting a reward -->
            <v-dialog v-if="rewardToRedeem !== null || rewardToDelete !== null" v-model="dialogs.redeemOrDelete"
                max-width="500" max-height="500" persistent>
                <v-card class="pa-4">
                    <v-card-title><span class="mx-auto"
                            :style="`${rewardToRedeem !== null ? 'color: #0D47A1;' : 'color: red;'}`">{{ rewardToRedeem !==
                                null
                                ? 'REDEEM REWARD' :
                                'DELETE REWARD' }}</span></v-card-title>
                    <v-card-text class="text-center">
                        {{ rewardToRedeem !== null ? "Would you like to redeem this reward?" : "Are you sure you want to
                        delete
                            this reward ? " }}
                    </v-card-text>

                    <v-card outlined flat class="d-flex py-2 mb-6">
                        <v-img id="rewardPhoto"
                            :src="require('../../assets/rewards/' + `${rewardToRedeem !== null ? rewardToRedeem.image : rewardToDelete.image}` + '.png')"
                            contain max-width="75" max-height="75" class="ml-2 mr-2"></v-img>

                        <div style="width: 100%;" class="d-flex flex-column justify-space-between">
                            <p class="text-subtitle-2 font-weight-bold">{{ rewardToRedeem !== null ? rewardToRedeem.name :
                                rewardToDelete.name }}</p>
                            <v-card-text class="pa-0 d-flex align-center ma-0">
                                <p class="text-body ma-0">{{ rewardToRedeem !== null ? rewardToRedeem.points :
                                    rewardToDelete.points }} points</p>
                            </v-card-text>
                        </div>
                    </v-card>

                    <v-btn block class="mt-2 white--text" :color="`${rewardToRedeem !== null ? '#0D47A1' : 'red'}`"
                        @click="rewardToRedeem !== null ? redeemReward(rewardToRedeem) : deleteReward(rewardToDelete)">
                        <v-icon class="mr-2">{{ rewardToRedeem !== null ? 'mdi-gift' : 'mdi-delete' }}</v-icon>
                        {{ rewardToRedeem !== null ? 'REDEEM' : 'DELETE ' }}
                    </v-btn>
                    <v-btn block outlined class="mt-2" color="red"
                        @click="dialogs.redeemOrDelete = false; rewardToRedeem !== null ? rewardToRedeem = null : rewardToDelete = null">
                        <v-icon class="mr-2">mdi-close</v-icon>
                        CANCEL
                    </v-btn>
                </v-card>
            </v-dialog>

            <!-- Snackbar for successful redeem of reward -->
            <v-snackbar class="text-center" v-model="snackbars.redeem.isSnackbar" :timeout="snackbars.redeem.timeout" top
                :color="snackbars.redeem.isSuccess ? 'success' : 'red accent-2'">
                {{ snackbars.redeem.message }}
            </v-snackbar>

            <!-- Dialog for viewing all redeemed rewards of a student upon scanning the qr code -->
            <v-dialog v-if="decodedText !== null" v-model="dialogs.viewRewards" max-width="500" max-height="500" persistent>
                <v-card class="pa-4">
                    <v-card-title><span class="mx-auto" style="color: #0D47A1;">USE REWARD</span></v-card-title>
                    <v-card-text class="text-center">
                        Choose which reward would be used.
                    </v-card-text>

                    <v-card outlined flat class="d-flex py-2 mb-2" v-if="claimedRewards.length !== 0"
                        v-for="(reward, index) in claimedRewards" :key="index">
                        <v-img id="rewardPhoto" :src="require('../../assets/rewards/' + reward.image + '.png')" contain
                            max-width="75" max-height="75" class="ml-2 mr-2"></v-img>

                        <div style="width: 100%;" class="d-flex flex-column justify-space-between">
                            <p class="text-subtitle-2 font-weight-bold">{{ reward.name }}</p>
                            <v-card-text class="pa-0 d-flex align-center ma-0">
                                <p class="text-body ma-0">{{ reward.points }}</p>
                                <v-btn x-small outlined rounded class="blue--text my-0 mr-2 ml-auto"
                                    @click="useReward(reward.id)">Choose</v-btn>
                            </v-card-text>
                        </div>
                    </v-card>

                    <v-btn block outlined class="mt-6" color="red"
                        @click="dialogs.viewRewards = false; claimedRewards = []; emptyDecodedText();">
                        <v-icon class="mr-2">mdi-close</v-icon>
                        CANCEL
                    </v-btn>
                </v-card>
            </v-dialog>

            <!-- Snackbar for using a reward -->
            <v-snackbar class="text-center" v-model="snackbars.use.isSnackbar" :timeout="snackbars.use.timeout" top
                :color="snackbars.use.isSuccess ? 'success' : 'red accent-2'">
                {{ snackbars.use.message }}
            </v-snackbar>
            <!-- Snackbar for deleting a reward -->
            <v-snackbar class="text-center" v-model="snackbars.delete.isSnackbar" :timeout="snackbars.delete.timeout" top
                :color="snackbars.delete.isSuccess ? 'success' : 'red accent-2'">
                {{ snackbars.delete.message }}
            </v-snackbar>
        </div>
    </v-container>
</template>

<script defer>
import Table from '../../components/Table'
import Scanner from '../ScanCode/Scanner.vue'
import { mapActions, mapState } from 'vuex'
import Loading from '../../components/Loading.vue'

export default {
    name: 'Gamify',

    components: {
        Table,
        Scanner,
        Loading
    },

    data: () => ({
        isPageLoaded: false,
        currentUser: null,
        scannedStudent: null,
        snackbars: {
            redeem: {
                isSnackbar: false,
                isSuccess: false,
                timeout: 3000,
                message: ''
            },
            use: {
                isSnackbar: false,
                isSuccess: false,
                timeout: 3000,
                message: ''
            },
            delete: {
                isSnackbar: false,
                isSuccess: false,
                timeout: 3000,
                message: ''
            }
        },
        scannerButtons: {
            save: false,
            discard: false,
            reward: true
        },

        dialogs: {
            reward: false,
            redeemOrDelete: false,
            viewRewards: false
        },

        newRewardForm: {
            name: '',
            points: null,
            image: '',
            id: null
        },
        rewardImage: {
            award: 'award.png',
            badge: 'badge.png',
            gear: 'gear.png',
            gift: 'gift.png',
            idea: 'idea.png',
            love: 'love.png'

        },

        claimedRewards: [],

        rewardToRedeem: null,
        rewardToDelete: null,

        imageChoices: [
            {
                fileName: 'award.png',
                value: 'award',
                color: 'transparent',
                isSelected: false
            },
            {
                fileName: 'badge.png',
                value: 'badge',
                color: 'transparent',
                isSelected: false
            },

            {
                fileName: 'gear.png',
                value: 'gear',
                color: 'transparent',
                isSelected: false
            },
            {
                fileName: 'gift.png',
                value: 'gift',
                color: 'transparent',
                isSelected: false
            },
            {
                fileName: 'idea.png',
                value: 'idea',
                color: 'transparent',
                isSelected: false
            },
            {
                fileName: 'love.png',
                value: 'love',
                color: 'transparent',
                isSelected: false
            }
        ],
        tableHeaders: [
            {
                text: 'FIRST NAME',
                value: 'firstName',
                align: 'start'
            },
            {
                text: 'MIDDLE NAME',
                value: 'middleName',
                // align: 'start'
            },
            {
                text: 'LAST NAME',
                value: 'lastName',
                // align: 'start'
            },
            {
                text: 'TOTAL POINTS',
                value: 'points',
                // align: 'start'
            }
        ]
    }),

    methods: {
        ...mapActions('gamify', [
            'updateStudentRewards',
            'getAllAchievements',
            'saveNewReward',
            'getAllRewards',
            'getPerfectAttendanceAward',
            'claimReward',
            'deleteSavedReward']),

        ...mapActions('students', ['getAllStudents', 'getSingleStudent']),
        ...mapActions('scanCode', ['emptyDecodedText']),

        addReward: function () {
            this.newRewardForm.id = Math.floor(Math.random() * 1000000)
            this.newRewardForm.points = parseInt(this.newRewardForm.points, 10)
            console.log(this.newRewardForm);
            this.saveNewReward(this.newRewardForm)

            this.newRewardForm = {
                name: '',
                points: null,
                image: '',
                id: null
            }

            this.imageChoices.forEach(choice => {
                choice.color = 'transparent'
            })

            this.getAllRewards()
            this.dialogs.reward = false
        },

        selectImage: function (image) {
            this.newRewardForm.image = image
            this.imageChoices.forEach(choice => {
                if (choice.value === image) {
                    choice.color = '#0D47A1'
                } else {
                    choice.color = 'transparent'
                }
            })
        },

        selectRewardToRedeem: function (reward) {
            this.dialogs.redeemOrDelete = true
            this.rewardToRedeem = {
                id: reward.id,
                name: reward.name,
                points: reward.points,
                image: reward.image
            }
        },

        selectRewardToDelete: function (reward) {
            console.log(reward)
            this.dialogs.redeemOrDelete = true
            this.rewardToDelete = {
                id: reward.id,
                name: reward.name,
                points: reward.points,
                image: reward.image
            }
        },

        redeemReward: function (reward) {
            this.student.rewards.push(reward.id.toString())

            if (Number(this.student.points) >= Number(reward.points)) {
                this.student.points = Number(this.student.points) - Number(reward.points)
                this.snackbars.redeem.isSuccess = true
                this.snackbars.redeem.message = "Ticket has been redeemed successfully"

                this.claimReward(this.student)
            } else {
                this.snackbars.redeem.isSuccess = false
                this.snackbars.redeem.message = "You don't have enough points"
            }

            this.snackbars.redeem.isSnackbar = true
            this.dialogs.redeemOrDelete = false
            this.rewardToRedeem = null
        },

        deleteReward: function (reward) {
            console.log(reward);
            this.deleteSavedReward(reward)
            this.getAllRewards()
            this.dialogs.redeemOrDelete = false
            this.snackbars.delete.message = 'Reward has been deleted successfully'
            this.snackbars.delete.isSuccess = true
            this.snackbars.delete.isSnackbar = true
            // this.student.rewards.push(reward.id.toString())

            // if (Number(this.student.points) >= Number(reward.points)) {
            //     this.student.points = Number(this.student.points) - Number(reward.points)
            //     this.snackbars.redeem.isSuccess = true
            //     this.snackbars.redeem.message = "Ticket has been redeemed successfully"

            //     this.claimReward(this.student)
            // } else {
            //     this.snackbars.redeem.isSuccess = false
            //     this.snackbars.redeem.message = "You don't have enough points"
            // }

            // this.snackbars.redeem.isSnackbar = true
            // this.dialogs.redeemOrDelete = false
            // this.rewardToRedeem = null
        },

        useReward: function (rewardId) {
            this.updateStudentRewards({ student: this.scannedStudent, reward: rewardId })
            this.emptyDecodedText()
            this.claimedRewards = []
            this.getAllStudents()
            this.dialogs.viewRewards = false
            this.snackbars.use.isSuccess = true
            this.snackbars.use.message = 'A reward has been used.'
            this.snackbars.use.isSnackbar = true
        },

        redirectToRiddles: function () {
            this.$router.push({ name: 'Riddles', params: { student: this.student } })
        }
    },

    computed: {
        ...mapState('gamify', {
            allAchievements: state => state.allAchievements,
            allRewards: state => state.allRewards,
            bestAttendees: state => state.bestAttendees
        }),
        ...mapState('students', {
            allStudents: state => state.allStudents,
            student: state => state.singleStudent
        }),

        ...mapState('scanCode', {
            decodedText: state => state.decodedText,
        }),

        savedRewards() {
            return [...this.allRewards]
        },

        tableItems() {
            let students = []
            if (this.bestAttendees) {
                this.allStudents.forEach(student => {
                    this.bestAttendees.awardees.forEach(awardee => {
                        if (student.reference.toString() === awardee.reference.toString()) {
                            students.push({
                                firstName: student.firstName.toUpperCase(),
                                middleName: student.middleName.toUpperCase(),
                                lastName: student.lastName.toUpperCase(),
                                points: student.points
                            })
                        }
                    })
                })
            }

            return students
        }
    },

    mounted: async function () {
        this.currentUser = JSON.parse(localStorage.getItem('user'))
        await this.getAllStudents()
        await this.getPerfectAttendanceAward()
        await this.getAllRewards()
        await this.getAllAchievements()

        if (this.currentUser.role === 'student') {
            await this.getSingleStudent(this.currentUser.reference)
        }

        this.isPageLoaded = true
    },

    watch: {
        decodedText(newValue, oldValue) {
            console.log(newValue);
            if (newValue !== null) {
                this.dialogs.viewRewards = true
                this.allStudents.forEach(student => {
                    if (student.apiKey === newValue && student.rewards.length !== 0) {
                        console.log(student.rewards);
                        for (var i = 0; i < student.rewards.length; i++) {
                            this.allRewards.forEach(reward => {
                                if (student.rewards[i].toString() === reward.id.toString()) {
                                    console.log(reward.id);
                                    this.claimedRewards.push(reward)
                                }
                            })
                        }

                        this.scannedStudent = student
                    }
                })
            }
        }
    }
};

</script>

<style scoped>
#reward-award-title {
    color: #0D47A1;
}

#rewardPhoto {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    /* background-color: #8bbaff; */
}

.imageChoices {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}

#riddle-prompt {
  background-color: rgb(242, 241, 255);
}

</style>
import Login from '../../views/Authentication/Login'
import Developers from '../../views/Authentication/Developers'
import Badges from '../../components/Easter.vue'

export default [
    {
        path: '/',
        name: 'Login',
        component: Login,
        meta: {
            isAuthenticated: false,
        }
    },
    {
        path: '/developers',
        name: 'Developers',
        component: Developers,
        meta: {
            isAuthenticated: false,
        }
    },
    {
        path: '/achievements',
        name: 'Achievements',
        component: Badges,
        meta: {
            isAuthenticated: true,
            permissions: ['DEVELOPER', 'INSTRUCTOR', 'STUDENT']
        }
    }
]
import Vue from 'vue'
import Vuex from 'vuex'
import { collection, addDoc, doc, setDoc, getDocs, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from '../../firebase'
import router from '@/router';

Vue.use(Vuex)

export const gamify = {
  namespaced: true,
  state: {
    allAchievements: [],
    allRewards: [],
    bestAttendees: null,
    allRiddles: [],
    snackbars: {
      buy: {
        isSnackbar: false,
        isSuccess: false,
        timeout: 3000,
        message: ''
      }
    }
  },
  getters: {
  },
  mutations: {
    SET_ALL_ACHIEVEMENTS: (state, payload) => state.allAchievements = payload,
    SET_ALL_REWARDS: (state, payload) => state.allRewards = payload,
    SET_ALL_RIDDLES: (state, payload) => state.allRiddles = payload,
    SET_BAA_AWARDEES: (state, payload) => state.bestAttendees = payload,
    SET_STATUS_MESSAGE: (state, payload) => {
      if (payload.snackbar === 'buy') {
        state.snackbars.buy.isSnackbar = payload.isSnackbar
        state.snackbars.buy.message = payload.message
        state.snackbars.buy.isSuccess = payload.isSuccess
      }
    },
  },
  actions: {
    async getAllAchievements({ commit }) {
      let achievements = []
      await getDocs(collection(db, "achievements"))
        .then(querySnapshot => {
          querySnapshot.forEach((doc) => {
            achievements.push(doc.data())
          })
        })
        .catch(error => {
          console.log('gamify getAllAchievements error');
          console.log(error);
        })

      commit('SET_ALL_ACHIEVEMENTS', achievements)
    },

    async getAllRewards({ commit }) {
      let rewards = []
      await getDocs(collection(db, "rewards"))
        .then(querySnapshot => {
          querySnapshot.forEach((doc) => {
            rewards.push(doc.data())
          })
        })
        .catch(error => {
          console.log(error);
        })

      commit('SET_ALL_REWARDS', rewards)
    },

    async saveNewReward({ commit }, data) {
      try {
        await setDoc(doc(db, "rewards", data.id.toString()), data)
      } catch (error) {
        console.log('gamify saveNewReward error');
        console.log(error);
      }
    },

    async updatePoints({ commit }, data) {
      try {
        console.log(data);
        await updateDoc(doc(db, "students", data.reference.toString()), { points: data.points });
      } catch (error) {
        console.log('gamify updatePoints error');
        console.log(error);
      }
    },

    async getPerfectAttendanceAward({ commit }) {
      const docRef = doc(db, "achievements", "BAA");
      const docSnap = await getDoc(docRef);

      //BAA for Best in Attendance Award
      if (docSnap.exists()) {
        commit('SET_BAA_AWARDEES', docSnap.data())
        console.log("Document data:", docSnap.data())
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    },

    async resetPerfectAttendanceAward({ commit }, data) {
      try {
        await updateDoc(doc(db, "achievements", "BAA"), { awardees: data.awardees, currentMonth: data.currentMonth });
      } catch (error) {
        console.log('gamify resetPerfectAttendanceAward error');
        console.log(error);
      }
    },

    async claimReward({ commit }, data) {
      try {
        await setDoc(doc(db, "students", data.reference.toString()), data)
      } catch (error) {
        console.log('gamify claimReward error');
        console.log(error);
      }
    },

    async updateStudentRewards({ commit }, data) {
      let updatedRewards = []
      const docRef = doc(db, "students", data.student.reference.toString());
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let rewards = docSnap.data().rewards
        let index = rewards.indexOf(data.reward.toString())
        console.log(index);
        console.log(rewards);
        if (index > -1) {
          rewards.splice(index, 1)
        }
        console.log(rewards);
        await updateDoc(doc(db, "students", data.student.reference.toString()), { rewards: rewards });
      } else {
        console.log("No such document!");
      }
    },

    async deleteSavedReward({ commit }, data) {
      console.log(data);
      try {
        await deleteDoc(doc(db, "rewards", data.id.toString()));
      } catch (error) {
        console.log(error);
      }
    },

    async emptyAwardNotice({ commit }, data) {
      try {
        console.log(data);
        await updateDoc(doc(db, "students", data.toString()), { awardNotice: [] });
      } catch (error) {
        console.log('gamify emptyAwardNotice error');
        console.log(error);
      }
    },

    async getAllRiddles({ commit }, data) {
      let riddles = []
      await getDocs(collection(db, "riddles"))
        .then(querySnapshot => {
          querySnapshot.forEach((doc) => {
            riddles.push({
              id: doc.id,
              ...doc.data()
            })
          })
        })
        .catch(error => {
          console.log(error);
        })

      commit('SET_ALL_RIDDLES', riddles)
    },

    async purchaseRiddle({ commit }, data) {
      commit('SET_STATUS_MESSAGE', { message: null, isSnackbar: false, isSuccess: false, snackbar: 'buy' })

      console.log(data);
      const docRef = doc(db, "students", data.reference.toString());
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        let deductRiddleCost = docSnap.data().points - 50
        if (docSnap.data().points >= 50) {
          try {
            await updateDoc(doc(db, "students", data.reference.toString()), { riddle: data.riddleId, points: deductRiddleCost });
            commit('SET_STATUS_MESSAGE', { message: 'Purchase successful.', isSnackbar: true, isSuccess: true, snackbar: 'buy' })
            router.push({ name: 'Gamify' })
          } catch (error) {
            console.log(error);
            commit('SET_STATUS_MESSAGE', { message: 'Failed to purchase riddle. Try again.', isSnackbar: true, isSuccess: false, snackbar: 'buy' })
          }
          console.log("Document data:", docSnap.data())
        } else {
          commit('SET_STATUS_MESSAGE', { message: "You don't have enough points to purchase this riddle.", isSnackbar: true, isSuccess: false, snackbar: 'buy' })
        }
      } else {
        console.log("No such document!");
        commit('SET_STATUS_MESSAGE', { message: 'Failed to purchase riddle. Try again.', isSnackbar: true, isSuccess: false, snackbar: 'buy' })
      }
    },

    async rewardRiddlePoints({ commit }, data) {
      console.log(data);
      const docRef = doc(db, "students", data.reference.toString());
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        let rewardPoints = docSnap.data().points + data.pointsEarned
        let updatedAnsweredRiddles = docSnap.data().answeredRiddles
        updatedAnsweredRiddles.push(data.riddleId.toString())
        console.log(updatedAnsweredRiddles)

        try {
          await updateDoc(doc(db, "students", data.reference.toString()), { answeredRiddles: updatedAnsweredRiddles, points: rewardPoints });
        } catch (error) {
          console.log(error);
        }

      } else {
        console.log("No such document!");
      }
    },

    async resetRiddle({ commit }, data) {
      try {
        console.log(data);
        await updateDoc(doc(db, "students", data.toString()), { riddle: "0" });
      } catch (error) {
        console.log('gamify resetRiddle error');
        console.log(error);
      }
    },

  },
  modules: {
  }
}
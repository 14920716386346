import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

//Routes
import DashboardRoutes from './modules/dashboard'
import AuthenticationRoutes from './modules/authentication'
import ScanCodeRoutes from './modules/scan_code'
import StudentsRoutes from './modules/students'
import AttendanceRoutes from './modules/attendance'
import GamifyRoutes from './modules/gamify'
import RiddlesRoutes from './modules/riddles'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },

  ...DashboardRoutes,
  ...AuthenticationRoutes,
  ...ScanCodeRoutes,
  ...StudentsRoutes,
  ...AttendanceRoutes,
  ...GamifyRoutes,
  ...RiddlesRoutes
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const user = JSON.parse(localStorage.getItem('user'))

  if (to.meta.isAuthenticated) {
    console.log(to.meta);
    if (user !== null) {
      if (to.meta.permissions.includes(user.role.toUpperCase())) {
        return next()
      } else if (user.role === 'instructor' || user.role === 'developer') {
        return next({ name: 'Dashboard' })
      } else {
        return next({ name: 'Profile' })
      }
    } else {
      return next({ name: 'Login' })
    }
  } else {
    // const user = JSON.parse(localStorage.getItem('user'))

    if (user !== null) {
      if (user.role === 'instructor' || user.role === 'developer') {
        return next({ name: 'Dashboard' })
      } else {
        return next({ name: 'Profile' })
      }
    } else {
      return next()
    }
  }
})

export default router

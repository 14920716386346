import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const scanCode = {
  namespaced:true,
  state: {
    decodedText: null,
  },
  getters: {
  },
  mutations: {
    SET_DECODED_TEXT: (state, payload) => state.decodedText = payload,
  },
  actions: {
    setScannedText: ({ commit }, data) => {
      console.log('text received');
      commit('SET_DECODED_TEXT', data)
    },

    emptyDecodedText: ({ commit }) => {
      commit('SET_DECODED_TEXT', null)
    },

  },
  modules: {
  }
}
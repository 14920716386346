import Vue from 'vue'
import Vuex from 'vuex'

import { attendance } from '../store/modules/attendance'
import { authentication } from '../store/modules/authentication'
import { dashboard } from '../store/modules/dashboard'
import { scanCode } from '../store/modules/scan_code'
import { students } from '../store/modules/students'
import { gamify } from '../store/modules/gamify'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    attendance,
    authentication,
    dashboard,
    scanCode,
    students,
    gamify
  }
})

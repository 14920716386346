import { render, staticRenderFns } from "./StudentDetails.vue?vue&type=template&id=5095101c&scoped=true&"
import script from "./StudentDetails.vue?vue&type=script&lang=js&"
export * from "./StudentDetails.vue?vue&type=script&lang=js&"
import style0 from "./StudentDetails.vue?vue&type=style&index=0&id=5095101c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5095101c",
  null
  
)

export default component.exports
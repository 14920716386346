import ScanCode from '../../views/ScanCode/ScanCode'

export default [
    {
        path: '/scan-code',
        name: 'Scan Code',
        component: ScanCode,
        meta: {
            isAuthenticated: true,
            permissions: ['DEVELOPER', 'INSTRUCTOR']
        }
    },
]
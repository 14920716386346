import Dashboard from '../../views/Dashboard/Dashboard'

export default [
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            isAuthenticated: true,
            permissions: ['DEVELOPER', 'INSTRUCTOR']
        }
    },
]
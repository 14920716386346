// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAGlSNTDg04KUHrb0GnmLC6smwq6T9Aavw",
  authDomain: "chronocheck-16fd9.firebaseapp.com",
  projectId: "chronocheck-16fd9",
  storageBucket: "chronocheck-16fd9.appspot.com",
  messagingSenderId: "608492260555",
  appId: "1:608492260555:web:0cd93cda0177c5d37e1f4e",
  measurementId: "G-0FNH8XKB88"
}

try {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  var db = getFirestore(app);
  var auth = getAuth(app);
} catch (error) {
  if (error.code === 'unavailable') {
    console.log('Firestore backend is unavailable');
  } else {
    console.error('An error occurred:', error);
  }
}

export { db, auth }
<template>
  <div id="scanner-wrapper" class="ma-auto">
    <!-- The div with id of qrcode-scanner holds the actual scanner -->
    <div id="qrcode-scanner" class="ma-auto">
      <v-card-text class="font-italic text-caption">
        Start the scanner to enable scanning.
      </v-card-text>
    </div>
    <div style="width: 100%;">
      <v-btn block class="mt-2 white--text" color="#1A237E" @click="startScanner()">
        <v-icon class="mr-2">mdi-camera</v-icon>
        START SCANNER
      </v-btn>

      <v-btn block class="mt-2 white--text" color="#303F9F" @click="resumeScan()">
        <v-icon class="mr-2">mdi-play</v-icon>
        RESUME SCAN
      </v-btn>

      <v-btn block class="mt-2 white--text" color="#1E88E5" @click="pauseScan()">
        <v-icon class="mr-2">mdi-pause</v-icon>
        PAUSE SCAN
      </v-btn>

      <v-btn v-if="buttons.save" block outlined class="mt-2" color="#2196F3" @click="saveAttendance()">
        <v-icon class="mr-2">mdi-content-save</v-icon>
        SAVE ATTENDANCE
      </v-btn>

      <v-btn v-if="buttons.reward" block outlined class="mt-2" color="#2196F3" @click="addReward()">
        <v-icon class="mr-2">mdi-plus-box</v-icon>
        ADD REWARD
      </v-btn>

      <v-dialog v-model="dialogs.discard" max-width="500" max-height="500" persistent>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-if="buttons.discard" block text class="mt-2" color="red" v-on="on">
            <v-icon class="mr-2">mdi-delete</v-icon>
            DISCARD ATTENDANCE
          </v-btn>
        </template>

        <v-card class="pa-4">
          <v-card-title><span class="mx-auto" style="color: red;">WARNING</span></v-card-title>
          <v-card-text class="text-center">
            Are you sure you want to discard this attendance? All sent notifications won't be undone.
          </v-card-text>

          <v-btn block class="mt-2 white--text" color="red" @click="discardAttendance()">
            <v-icon class="mr-2">mdi-delete</v-icon>
            DELETE ANYWAY
          </v-btn>
          <v-btn block outlined class="mt-2" color="red" @click="dialogs.discard = false">
            <v-icon class="mr-2">mdi-close</v-icon>
            CLOSE
          </v-btn>
        </v-card>
      </v-dialog>

    </div>
  </div>
</template>

<script>
import { Html5Qrcode } from 'html5-qrcode';
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Scanner',

  props: ['buttons'],

  data: () => ({
    scanner: null,
    config: {},
    buttonColor: '#0D47A1',
    dialogs: {
      discard: false
    }
  }),

  methods: {
    ...mapActions('scanCode', ['setScannedText']),
    ...mapActions('attendance', ['discardNewAttendance']),

    startScanner: function () {
      // Starts the scanner and catch any error
      try {
        this.scanner.start({ facingMode: "environment" }, this.config, this.onScanSuccess)
          .then((result) => {
            console.log(result);
          })
          .catch((error) => {
            console.log(error);
          })
      } catch (error) {
        console.log(error);
      }
    },

    stopScanner: function () {
      try {
        this.scanner.stop()
      } catch (error) {
        console.log(error);
      }
    },

    onScanSuccess(decodedText, decodedResult) {
      this.setScannedText(decodedText)

      try {
        this.scanner.pause()
      } catch (error) {
        console.log('scanner onScanSuccess error');
        console.log(error);
      }
    },

    resumeScan: function () {
      try {
        this.scanner.resume()
      } catch (error) {
        console.log('scanner resumeScan error');
        console.log(error);
      }
    },

    pauseScan: function () {
      try {
        this.scanner.pause()
      } catch (error) {
        console.log('scanner pauseScan error');
        console.log(error);
      }
    },

    addReward: function () {
      this.$emit('addNewReward')
    },

    saveAttendance: function () {
      this.$emit('addAttendance')
    },

    discardAttendance: function () {
      //TO DO: Make this an event emitter
      // this.pauseScan()
      this.discardNewAttendance(true)
      this.dialogs.discard = false
      localStorage.removeItem('attendance')
      localStorage.removeItem('attendance-date')
    }
  },

  mounted() {
    this.scanner = new Html5Qrcode('qrcode-scanner')

    try {
      // Scanner configuration
      this.config = {
        fps: "10",
        qrbox: 250,
        aspectRatio: "1",
      }

      // Dynamically sets the dimensions of the box within the scanner
      this.config.qrbox = function (viewfinderWidth, viewfinderHeight) {
        let minEdgePercentage = 0.7
        let minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight)
        let qrboxSize = Math.floor(minEdgeSize * minEdgePercentage)
        return {
          width: qrboxSize,
          height: qrboxSize
        };
      }

    } catch (error) {
      console.log(error);
    }

  },

  beforeDestroy(){
    this.stopScanner()
  }
};

</script>

<style scoped>
#scanner-wrapper {
  width: 100%;
  max-width: 500px;
}

#qrcode-scanner {
  width: 100%;
  border: 1px solid #0D47A1;
  border-radius: 5px;
  background-color: rgb(242, 241, 255);
}
</style>